import React, { useState } from 'react';
import styled from 'styled-components';

import BananaEmoji from 'app/src/components/emoji/BananaEmoji';
import CatEmoji from 'app/src/components/emoji/CatEmoji';
import CoconutEmoji from 'app/src/components/emoji/CoconutEmoji';
import UnicornEmoji from 'app/src/components/emoji/UnicornEmoji';
import { isMobile } from 'shared/vars';

const Container = styled.div`
	margin: ${isMobile ? '74px' : '200px'} auto 40px;
	text-align: center;
	font-family: 'Proxima Nova', Arial;
	font-size: 16px;
	line-height: 20px;
`;

const EmptyTitle = styled.div`
	font-weight: bold;
	margin-bottom: 4px;
	margin-top: -24px;
	color: ${props => props.theme.grey3};
`;

const EmptyDesc = styled.div`
	color: ${props => props.theme.grey3};
`;

const variants = [
	{
		emoji: BananaEmoji,
		emojiName: 'banana',
	},
	{
		emoji: CatEmoji,
		emojiName: 'cat',
	},
	{
		emoji: UnicornEmoji,
		emojiName: 'unicorn',
	},
	{
		emoji: CoconutEmoji,
		emojiName: 'coconut',
	},
];

const EmptyMessage = props => {
	const { type = 'place', desc, title } = props;

	// Pick a random variant
	const [{ emoji: Emoji, emojiName }] = useState(() => variants[Math.floor(Math.random() * variants.length)]);

	return (
		<Container>
			<Emoji inactive />
			<EmptyTitle>{title ?? `This ${type} is empty, so here is a ${emojiName} instead.`}</EmptyTitle>
			<EmptyDesc>{desc}</EmptyDesc>
		</Container>
	);
};

export default EmptyMessage;

export { Container as EmptyMessageContainer };
