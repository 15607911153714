export default function formatNumber(numIn, { shorten = false, allowDecimals = false } = {}) {
	const num = Number(numIn || 0);
	let result = num.toFixed(allowDecimals ? 2 : 0);

	if (num < 1000 && num > 0 && allowDecimals) { // Easy way out for small numbers
		result = num.toFixed(2);
	} else if (num > 0 && num < 1) { // If number is a fraction, force decimals
		result = num.toFixed(2);
	} else if (shorten) { // Shorten large numbers
		const round = Math.round(num);
		if (round >= 1000000) result = `${(round / 1000000).toFixed(round < 10000000 ? 1 : 0)}M`;
		else if (round >= 1000) result = `${(round / 1000).toFixed(round < 10000 ? 1 : 0)}K`;
	}

	// Add thousands separators
	return String(result).replace(/\B(?=(\d{3})+(?!\d))/g, ','); // https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
}
