import createAction from 'app/src/utils/createAction';

export const hydrated = createAction('client/hydrated');
export const setViewport = createAction('client/set_viewport');
export const openModal = createAction('client/modal_open');
export const closeModal = createAction('client/modal_close');
export const toggleNavigation = createAction('client/toggle_navigation');
export const toggleDateRangeInput = createAction('client/toggle_date_range_input');
export const toggleIsDragging = createAction('client/toggle_is_dragging');
export const openToast = createAction('client/toast_open');
export const closeToast = createAction('client/toast_close');
export const preventPageLeave = createAction('client/prevent_page_leave');
export const uncaughtServerError = createAction('client/uncaught_server_error');
export const addPermalinkKeyValue = createAction('client/add_permalink_key_value');
export const clearPermalinkKeyValues = createAction('client/clear_permalink_key_values');
export const copyPermalink = createAction('client/copy_permalink');
export const locationChanged = createAction('client/locationChanged');
