const imagesBlue = [
	'https://a.poki-cdn.com/emoji/hand_fingers_isolated.svg',
	'https://a.poki-cdn.com/emoji/space_isolated.svg',
	'https://a.poki-cdn.com/emoji/bicep_isolated.svg',
	'https://a.poki-cdn.com/emoji/smiley_isolated.svg',
	'https://a.poki-cdn.com/emoji/banana_isolated.svg',
	'https://a.poki-cdn.com/emoji/thumb_isolated.svg',
	'https://a.poki-cdn.com/emoji/fire_isolated.svg',
	'https://a.poki-cdn.com/emoji/controller_isolated.svg',
	'https://a.poki-cdn.com/emoji/turd_isolated.svg',
	'https://a.poki-cdn.com/emoji/unicorn_isolated.svg',
	'https://a.poki-cdn.com/emoji/rocket_isolated.svg',
	'https://a.poki-cdn.com/emoji/cocos_isolated.svg',
	'https://a.poki-cdn.com/emoji/ufo_isolated.svg',
	'https://a.poki-cdn.com/emoji/bike_isolated.svg',
	'https://a.poki-cdn.com/emoji/hundred_isolated.svg',
	'https://a.poki-cdn.com/emoji/cat_isolated.svg',
	'https://a.poki-cdn.com/emoji/cool_glasses_isolated.svg',
	'https://a.poki-cdn.com/emoji/caterpillar_isolated.svg',
	'https://a.poki-cdn.com/emoji/palm_isolated.svg',
	'https://a.poki-cdn.com/emoji/heart_isolated.svg',
];

const imagesWhite = [
	'https://a.poki-cdn.com/emoji/hand_fingers_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/space_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/bicep_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/smiley_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/banana_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/thumb_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/fire_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/controller_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/turd_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/unicorn_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/rocket_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/cocos_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/ufo_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/bike_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/hundred_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/cat_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/cool_glasses_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/caterpillar_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/palm_isolated_white.svg',
	'https://a.poki-cdn.com/emoji/heart_isolated_white.svg',
];

const cache = [];

export const spawnEmoji = (el, options = {}) => {
	const emoji = cache.shift();
	const { position = null, style = 'default', forceImage = null } = options;

	if (!emoji) return;

	const { container, image } = emoji;

	let left;
	let top;

	if (position) {
		({ left, top } = position);
	} else {
		const bounds = el.getBoundingClientRect();
		left = bounds.left + bounds.width;
		({ top } = bounds);
	}

	container.style.left = `${left}px`;
	container.style.top = `${top}px`;

	const images = style === 'white' ? imagesWhite : imagesBlue;

	const src = images[Math.floor(Math.random() * images.length)];
	image.style.backgroundImage = `url("${forceImage || src}")`;

	if (style === 'white') {
		container.classList.add('emojiHover_white');
	}

	window.setTimeout(() => {
		container.classList.add('emojiHover_animate');

		window.setTimeout(() => {
			container.classList.remove('emojiHover_animate');
			container.classList.remove('white');
			cache.push(emoji);
		}, 800);
	}, 16);
};

const create = () => {
	const width = 128;
	const height = 128;

	const container = document.createElement('div');
	container.className = 'emojiHover_container';

	const wrapper = document.createElement('div');
	wrapper.className = 'emojiHover_wrapper';

	container.appendChild(wrapper);

	const sparklesLeft = document.createElement('div');
	sparklesLeft.className = 'emojiHover_sparklesLeft';

	wrapper.appendChild(sparklesLeft);

	const sparklesRight = document.createElement('div');
	sparklesRight.className = 'emojiHover_sparklesRight';

	wrapper.appendChild(sparklesRight);

	const image = document.createElement('div');
	image.className = 'emojiHover_image';
	image.style.width = `${width}px`;
	image.style.height = `${height}px`;

	wrapper.appendChild(image);

	document.body.appendChild(container);

	cache.push({ container, image });
};

export const prep = () => {
	const style = document.createElement('style');
	style.innerHTML = `
	.emojiHover_container {
		position: fixed;
		z-index: 1000;
		pointer-events: none;
	}

	.emojiHover_wrapper {
		position: relative;
		transform: translate(-50%, -95%);
	}

	.emojiHover_image {
		position: relative;
		transform: translate3d(0, 50px, 0) scale(.75, 1);
		z-index: 1;
		opacity: 0;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		will-change: transform;
	}

	.emojiHover_sparklesLeft,
	.emojiHover_sparklesRight {
		position: absolute;
		top: 50%;
		height: 128px;
		width: 64px;
		background-position: 0 50%;
		background-size: auto 100%;
		z-index: -1;
		will-change: transform;
	}

	.emojiHover_sparklesLeft {
		left: 0;
		transform: translate(-10%, -50%);
		background-image: url(https://a.poki-cdn.com/emoji/8bit-sparkle-left-blue.png)
	}

	.emojiHover_sparklesRight {
		right: 0;
		transform: translate(10%, -50%);
		background-image: url(https://a.poki-cdn.com/emoji/8bit-sparkle-right-blue.png)
	}

	.emojiHover_white .emojiHover_sparklesLeft {
		background-image: url(https://a.poki-cdn.com/emoji/8bit-sparkle-left-white.png)
	}

	.emojiHover_white .emojiHover_sparklesRight {
		background-image: url(https://a.poki-cdn.com/emoji/8bit-sparkle-right-white.png)
	}

	.emojiHover_animate .emojiHover_image {
		animation: emojiHover_imageAnimation .5s linear forwards;
	}

	.emojiHover_animate .emojiHover_sparklesLeft,
	.emojiHover_animate .emojiHover_sparklesRight {
		animation: emojiHover_sparkleAnimation .8s steps(5);
	}

	@keyframes emojiHover_imageAnimation {
		0% {
			transform: translate3d(0, 50px, 0) scale(.75, 1);
			opacity: 1
		}

		75% {
			transform: translate3d(0, 0, 0) scale(1, 1);
			opacity: 1
		}

		100% {
			transform: translate3d(0, -10px, 0) scale(1, 1);
			opacity: 0
		}
	}

	@keyframes emojiHover_sparkleAnimation {
		100% {
			background-position: -320px
		}
	}`;

	document.head.appendChild(style);

	// Create images
	// Amount is arbitrary. Enough to make sure you can spam it a bit, not too many so we don't overload the DOM
	for (let i = 0; i < 15; i++) {
		create();
	}
};
