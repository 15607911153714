import { useSelector } from 'react-redux';

export const selectUser = state => state.user.user;
export const selectPermissions = state => state.user.permissions || [];
export const selectIsLockedOut = state => !!state.user.teamTemporarilyLockedOut;
export const selectImpersonator = state => state.user.impersonator;
export const selectIsAdmin = state => state.user.user.role === 'admin';

export const useSelectUser = () => useSelector(selectUser);
export const useSelectPermissions = () => useSelector(selectPermissions);
export const useSelectIsLockedOut = () => useSelector(selectIsLockedOut);
export const useSelectImpersonator = () => useSelector(selectImpersonator);
export const useSelectIsAdmin = () => useSelector(selectIsAdmin);
