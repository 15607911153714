{
	"engine": "Godot",
	"sub-title": "GDScript/C++ Engine",
	"official-partner": false,
	"website": "https://godotengine.org/",
	"documentation": "https://docs.godotengine.org/en/stable/",
	"community-links": [
		{
			"label": "Community",
			"url": "https://godotengine.org/community/"
		}
	],
	"description": "Godot is a 2D and 3D capable game engine, often compared to Unity given the similarities. With the release of its latest version, Godot 4.0, this engine has seen a lot of interest and new users. It’s community is growing fast and the quality of games made with this engine is getting better with each release!",
	"games": [
		{
			"title": "Super Liquid Soccer",
			"url": "https://poki.com/en/g/super-liquid-soccer",
			"developer": "Punyrobot"
		},
		{
			"title": "Chicky Farm",
			"url": "https://poki.com/en/g/chicky-farm",
			"developer": "PT New Game Plus"
		},
		{
			"title": "Rooftop Snipers",
			"url": "https://poki.com/en/g/rooftop-snipers",
			"developer": "NewEichGames"
		}
	],
	"sections": {
		"tech-fit": {
			"file-size-of-empty-project": {
				"stars": 2,
				"description": "The compressed size of an empty Godot project is 10MB."
			},
			"support-for-mobile-web": {
				"stars": 2,
				"description": "With support for responsive scaling to Portrait and Landscape orientations and touch controls, Godot is a good fit for mobile web games. The comparatively large initial file size can lead to longer loading times on older devices or weaker internet connections. With the release of Godot 4.3, the use of SharedBufferArray in web exports becomes optional. This technique was incompatible with a lot of existing websites. For exclusive export to the web, we recommend using the latest version of Godot 3.5. This version of the engine also has a OpenGL option aimed at mobile platforms. Builds made in this version run faster on mobile, but it sacrifices some functionalities that its desktop counterpart has."
			}
		},
		"capabilities": {
			"2D": {
				"stars": 3,
				"description": "Godot has a fully specialised 2D pipeline including advanced 2D lighting, tilemap, text and sprite options. The editor also includes particle systems that can be handled on the CPU, and a GPU based implementation for hardware that support it. A particularly helpful feature in Godot is its extensive Animation Player. With this feature, any part of the engine can be animated. This allows you to make very detailed effects or create intricate functionalities."
			},
			"3D": {
				"stars": 3,
				"description": "Godot has a modern 3D renderer built in. Especially Godot 4.0 features many modern rendering techniques such as SDFGI, PBR materials, HDR support, various screen space and post processing effects. Godot also has a built-in physics engine that can handle both 2D and 3D physics."
			},
			"multiplayer": {
				"stars": 3,
				"description": "Godot does not have native integrations with back-end services. It does have a specific node for making connections to the web, which can be used to make calls to the back-end of your choice. This will require some setup. Besides this, Godot has an extensive Asset Library where other developers have uploaded scripts and plug-ins that can help you connect to your back-end. For each of the most popular back-end services (Nakama, Colyseus, Websockets, Playfab), there is a plug-in available."
			}
		},
		"team-fit": {
			"collaboration": {
				"stars": 3,
				"description": "The main editor features separate workspaces for 2D and 3D projects and a very intuitive scene editor. This makes it possible for both the graphical and coding part of your team to work together in the same place. Godot's method of storing project data also makes it very friendly towards version control tools such as Git, which makes it easy to work together on a project."
			},
			"licensing": {
				"stars": 3,
				"description": "Godot is an open source project, which means that licenses are free. Commercialising games made with Godot is also allowed without extra costs."
			}
		}
	}
}
