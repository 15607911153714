import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import ClipboardIcon from 'shared/designTokens/icons/ui/small/ClipboardIcon';
import _ from 'shared/copy';

const CopyToClipboardWrapper = styled.div`
	cursor: pointer;
`;

const CopyToClipboardIcon = styled(ClipboardIcon)`
	vertical-align: bottom;

	[fill] {
		fill: ${props => props.theme.pokiBlue};
	}

	&:hover {
		[fill] {
			fill: ${props => props.theme.pokiHover};
		}
	}
`;

const CopyToClipboard = ({ text, className }) => {
	const [copied, setCopied] = useState(false);
	const timeoutId = useRef();

	const handleCopy = () => {
		navigator.clipboard.writeText(text)
			.then(() => {
				setCopied(true);
			})
			.catch(err => {
				console.error('Failed to copy to clipboard', err);
				setCopied(false);
			});
	};

	useEffect(() => {
		if (copied) {
			timeoutId.current = setTimeout(() => setCopied(false), 1000);
		}

		return () => {
			clearTimeout(timeoutId);
		};
	}, [copied]);

	return (
		<CopyToClipboardWrapper
			className={className}
			title={copied ? _`copied` : _`copyToClipboard`}
			onClick={handleCopy}
		>
			<CopyToClipboardIcon />
		</CopyToClipboardWrapper>
	);
};

export default CopyToClipboard;
