import createAction from 'app/src/utils/createAction';

export const signout = createAction('session/signout');
export const signoutWithNewEmail = createAction('session/signout_with_new_email');
export const setAccessToken = createAction('session/set_access_token');
export const setRefreshToken = createAction('session/set_refresh_token');
export const setPreviousRefreshToken = createAction('session/set_previous_refresh_token');
export const setTokenTTL = createAction('session/set_token_ttl');
export const toggleDarkMode = createAction('session/toggle_dark_mode');
export const persistHideMessageBox = createAction('session/persist_hide_messagebox');
export const setCachedURLTeamCode = createAction('session/set_cached_url_team_code');
export const runAfterRefresh = createAction('session/run_after_refresh');
export const authRefreshed = createAction('session/auth_refreshed');
export const setVisitedTeam = createAction('session/visited_team');
export const setPreparedInvoice = createAction('session/prepared_invoice');
export const setUnpreparedInvoice = createAction('session/unprepared_invoice');
export const setInitialErrorsDetailsPagination = createAction('session/set_initial_error_details_pagination');
export const setErrorDetailsPagination = createAction('session/set_error_details_pagination');
export const setRedirectPathname = createAction('session/set_redirect_pathname');
export const clearRedirectPathname = createAction('session/clear_redirect_pathname');
export const setGameChecklistStatus = createAction('session/set_game_checklist_status');
export const setHasPlaytestNotifications = createAction('session/set_has_playtest_notifications');
