import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import TextInput from 'app/src/components/input/TextInput';

const StyledTextInput = styled(TextInput)`
	input {
		font-size: 16px;
	}
`;

const FilterTextInput = props => {
	const { name, placeholder, value, valueSetter, onChange, icon, small = false } = props;

	const [inputValue, setInputValue] = useState(value);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	useEffect(() => {
		const debouncedHandler = setTimeout(() => {
			valueSetter(inputValue);
		}, 500);

		return () => {
			clearTimeout(debouncedHandler);
		};
	}, [inputValue]);

	return (
		<StyledTextInput
			icon={icon}
			name={name}
			small={small}
			placeholder={placeholder}
			value={inputValue}
			valueSetter={setInputValue}
			onChange={onChange}
		/>
	);
};

export default FilterTextInput;
