const downloadFromBase64 = (dataString, filename) => {
	fetch(`data:application/pdf;base64,${dataString}`)
		.then(decoded => decoded.blob())
		.then(blob => {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				a.remove();
				window.URL.revokeObjectURL(url);
			}, 1000);
		});
};

export default downloadFromBase64;
