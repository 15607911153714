import { getTeamByCode, listTeams, listUsersByTeamId, getTeamRevenueShares, getTeamBillingSettings, getTeamById, listAllTeams, getTeamNewRevenueShares } from 'app/src/epics/team';
import dataFormatter from 'app/src/utils/dataFormatter';

const initialState = {
	allTeamsList: null,
	teamsList: null,
	teams: {},
	userListByTeamId: {},
	revenueShares: {},
	newRevenueShares: {},
	teamBillingSettings: {},
};

export default function teamReducer(state = initialState, action) {
	if (action.type === listTeams.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;

		return {
			...state,
			teamsList: {
				data: dataFormatter.deserialize(response),
				links,
				meta,
			},
		};
	}

	if (action.type === listAllTeams.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;

		return {
			...state,
			allTeamsList: {
				data: dataFormatter.deserialize(response),
				links,
				meta,
			},
		};
	}

	if (action.type === getTeamBillingSettings.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			teamBillingSettings: {
				...state.teamBillingSettings,
				[data.id]: {
					data,
					links,
					meta,
				},
			},
		};
	}

	if (action.type === getTeamByCode.success.type || action.type === getTeamById.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			teams: {
				...state.teams,

				[data.code]: {
					data,
					links,
					meta,
				},

				[data.id]: {
					data,
					links,
					meta,
				},
			},
		};
	}

	if (action.type === listUsersByTeamId.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			userListByTeamId: {
				...state.userListByTeamId,

				[action.payload.options.teamId]: {
					data,
					links,
					meta,
				},
			},
		};
	}

	if (action.type === getTeamNewRevenueShares.success.type) {
		const { result } = action.payload;
		const { response } = result;
		const { meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			newRevenueShares: {
				[action.payload.options.teamId]: {
					meta,
					data,
				},
			},
		};
	}

	if (action.type === getTeamRevenueShares.success.type) {
		const { result } = action.payload;
		const { response } = result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			revenueShares: {
				...state.revenueShares,

				[action.payload.options.teamId]: {
					data,
					links,
					meta,
				},
			},
		};
	}

	return state;
}
