import React from 'react';

const MobileCheckIcon = props => {
	const { className } = props;

	return (
		<svg className={className} viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M77.1895 52.77V22.5C77.1895 16.71 72.4795 12 66.6895 12H37.4395C31.6495 12 26.9395 16.71 26.9395 22.5V79.5C26.9395 85.29 31.6495 90 37.4395 90H53.1295C57.1495 93.72 62.4895 96.03 68.3995 96.03C80.8195 96.03 90.8995 85.92 90.8995 73.53C90.8995 64.23 85.2295 56.25 77.1895 52.8V52.77ZM35.9395 79.5V22.5C35.9395 21.69 36.6295 21 37.4395 21H66.6895C67.5295 21 68.1895 21.69 68.1895 22.5V51C55.8595 51.12 45.8695 61.17 45.8695 73.5C45.8695 76.14 46.3495 78.66 47.1895 81H37.4395C36.6295 81 35.9395 80.34 35.9395 79.5ZM68.3995 88.53C60.1195 88.53 53.3995 81.78 53.3995 73.53C53.3995 65.28 60.1495 58.53 68.3995 58.53C76.6495 58.53 83.3995 65.28 83.3995 73.53C83.3995 81.78 76.6495 88.53 68.3995 88.53ZM47.5795 29.3401H56.5795C57.8095 29.3401 58.8295 28.3201 58.8295 27.0901C58.8295 25.8601 57.8095 24.8401 56.5795 24.8401H47.5795C46.3495 24.8401 45.3295 25.8601 45.3295 27.0901C45.3295 28.3201 46.3495 29.3401 47.5795 29.3401ZM66.2696 73.9798L72.2096 67.5598L72.1496 67.5898C73.5596 66.0598 75.9596 65.9698 77.4596 67.3798C78.9896 68.7898 79.0796 71.1598 77.6696 72.6898L69.0896 81.9598C68.3996 82.7098 67.4396 83.1598 66.4196 83.1598H66.3596C65.3696 83.1598 64.4096 82.7398 63.7196 82.0498L58.7996 77.1298C57.3296 75.6598 57.3296 73.2898 58.7996 71.8198C60.2696 70.3498 62.6396 70.3498 64.1096 71.8198L66.2696 73.9798Z" fill="#009CFF" />
		</svg>
	);
};

export default MobileCheckIcon;
