import React from 'react';

import { useSelectTheme } from 'app/src/selectors/session';

const CoconutEmoji = props => {
	const { className, inactive } = props;
	const theme = useSelectTheme();

	return (
		<svg className={className} width="128" height="128" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M34 26H36V24H37V22H38V24H37V26H38V27H36V29H35V27H33V26H31V28H30V26H27V27H24V28H23V29H22V31H23V32H24V33H26V34H35V33H37V32H41V29H42V32V36H41V39H40V41H39V43H38V44H37V45H35V46H27V45H25V44H24V43H23V42H22V41H21V38H20V30H21V28H22V27H23V26H26V25H31V22H30V21H28V20H25V19H22V18H26V19H29V20H31V21H32V25H34V26ZM29 31V28H30V31H29ZM28 33V31H29V33H28ZM38 22V21H40V22H38ZM40 23V22H42V23H40ZM42 24V23H44V24H42ZM34 32V29H35V32H34ZM38 28V27H40V28H38ZM40 29V28H41V29H40Z"
				fill={inactive ? theme.grey5 : theme.pokiBlue}
				shapeRendering="crispEdges"
			/>
		</svg>
	);
};

export default CoconutEmoji;
