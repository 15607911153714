import React from 'react';
import styled from 'styled-components';

import { _large, _medium, _small, clipPathTop } from 'shared/vars';

import { spawnEmoji } from 'shared/utils/emojiHover';
import Button from 'app/src/components/ui/Button';

const SignUpCTAContainer = styled.div`
	background: url('/images/bg-seamless-diamonds.svg') no-repeat top center;
	background-size: cover;
	padding: 120px 40px 80px;
	clip-path: ${clipPathTop};
	margin: 0 0 36px;

	${_medium} {
		padding-top: 150px;
	}
`;

const SignUpCTAContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 24px;
	max-width: 644px;
	margin: 0 auto;

	${_medium} {
		max-width: 763px;
	}
`;

const SignUpCTATitle = styled.h2`
	font: 700 32px/1.25 Torus, Montserrat, Arial, sans-serif;
	margin: 0;

	${_small} {
		font-size: 40px;
	}

	${_large} {
		font-size: 52px;
	}
`;

const SignUpCTAText = styled.div`
	text-align: center;
	line-height: 1.55;

	${_large} {
		font-size: 22px;
	}

	p {
		margin: 0;
	}
`;

const ButtonWrapper = styled(Button)``;

const handleSpawnEmoji = evt => {
	spawnEmoji(evt.target);
};

const SignUpCTA = () => (
	<SignUpCTAContainer>
		<SignUpCTAContent>
			<SignUpCTATitle>Are you ready for web?</SignUpCTATitle>
			<SignUpCTAText>
				<p>Sign up and start using our dev tools to launch, improve, and skyrocket your game to success.</p>
			</SignUpCTAText>
			<ButtonWrapper marketingButton onMouseEnter={handleSpawnEmoji} to="/share">Join Us</ButtonWrapper>
		</SignUpCTAContent>
	</SignUpCTAContainer>
);

export default SignUpCTA;
