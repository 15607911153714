import { startScreenShake, stopScreenShake } from 'app/src/actions/effects';

const initialState = {
	isInScreenShake: false,
};

export default function effectsReducer(state = initialState, action) {
	if (action.type === startScreenShake.type) {
		return {
			...state,
			isInScreenShake: true,
		};
	}

	if (action.type === stopScreenShake.type) {
		return {
			...state,
			isInScreenShake: false,
		};
	}

	return state;
}
