{
	"engine": "Construct 3",
	"sub-title": "JavaScript & Visual Script Editor and Engine",
	"official-partner": false,
	"website": "https://www.construct.net/en",
	"documentation": "https://www.construct.net/en/tutorials?flang=1",
	"community-links": [
		{
		   "label": "Community",
		   "url": "https://www.construct.net/en/community"
		}
	],	   
	"description": "Construct 3 is a game engine focused on 2D games and fast prototyping. It has an event sheet based visual scripting language, and lets you use Javascript and Typescript if you prefer. It has many built in tools, like an animation editor, flowcharts, timelines, data and code editors. The engine has a modular addon system that makes iterating on the game really fast, and keeps export size small. With very extensive documentation, tutorials and courses in many languages, almost 400 examples and a very active community, Construct is one of the most accessible engines out there.",
	"games": [
		{
			"title": "Blumgi Slime",
			"url": "https://poki.com/en/g/blumgi-slime",
			"developer": "Blumgi"
		},
		{
			"title": "OvO Dimensions",
			"url": "https://poki.com/en/g/ovo-dimensions",
			"developer": "Dedra Games x Venturous"
		},
		{
			"title": "Idle Ants",
			"url": "https://poki.com/en/g/idle-ants",
			"developer": "Madbox"
		}
	],
	"sections": {
		"tech-fit": {
			"file-size-of-empty-project": {
				"stars": 3,
				"description": "File size of an empty Construct project is 730KB in size unzipped, and 342KB zipped."
			},
			"support-for-mobile-web": {
				"stars": 3,
				"description": "Construct 3 works very well on mobile. It has full support for mobile features, viewport scaling, and automatically handles safe area insets like notches. C3 also comes with a remote preview option to preview the game on any device and stream the screen to the editor. All C3 games are PWAs which means they can all be installed as apps on mobile devices. C3 also supports exporting with Cordova for IOS and Android native apps and can even package the game for you on their servers. C3 addons can implement cordova plugins to extend mobile native capabilities."
			}
		},
		"capabilities": {
			"2D": {
				"stars": 3,
				"description": "Construct is built for 2D. It has Sprites, 9 patches, tiled textures, tilemaps, particle system, mesh distortion, BBCode text, SVG, Spritefonts, HTML based UI and 2D lights. 3rd party developers have also added support for Spine and Spriter 2D animations, clipping masks, and UI systems. C3 has its own collision engine, but also implements Box2D for physics simulations and has a built in pathfinding system. Construct’s effect system lets you easily combine prebuilt shaders on any object or layer, or even do post processing on the entire scene."
			},
			"3D": {
				"stars": 1,
				"description": "Construct can do basic 3D. It has a 3D camera, and support for basic 3D shapes and 3D mesh distortion meant to add some perspective to 2D scenes. 3rd party developers have added gLTF support and are working on 3D physics using Rapier, 3D lighting, and 3D Particles with Effekseer. Shaders also work with 3D elements."
			},
			"multiplayer": {
				"stars": 3,
				"description": "Construct 3 comes with a WebRTC Peer to Peer Multiplayer system with a dedicated signaling server for matchmaking. There is also 3rd party support for Colyseus, Firebase, Photon and PlayFab"
			}
		},
		"team-fit": {
			"collaboration": {
				"stars": 3,
				"description": "Construct 3 is designed to work well with Git. All project files are saved to JSON which makes it easy to merge changes. Construct 3 also supports external code editors with live reload of script files on preview, and can generate typescript definitions for its scripting runtime for all included addons."
			},
			"licensing": {
				"stars": 2,
				"description": "Construct is a paid service. The engine is licensed under a subscription model, and pricing changes based on the scale of the team. Games exported with Construct do not owe any royalties to the Construct team."
			}
		}
	}
}