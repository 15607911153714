import React from 'react';

import { useSelectTheme } from 'app/src/selectors/session';

const BananaEmoji = props => {
	const { className, inactive } = props;
	const theme = useSelectTheme();

	return (
		<svg className={className} width="128" height="128" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28 32H29H30V31H31V30H30V29V28V27V26V25V24H29V23V22H28V21H27V22H26V23V24V25H25V26V27V28V29V30H26V31V32H27V33H26V34H25V33H24V34H23V35H22V36H21V37H22V39V40H23V42H24V43H25V44H26V43H27V40H26V39V37H27V38H28V39H29V40H30V41H31V42H32V43H34V44H40V43H41V42H42V41H43V40H42H40V39H38V38H36V37H35V36H34V35H33V31H34H35V32H36H37V33H38V34H39V35H40V36H41V37H42V36V35V34H41V33H40V32H39V31V30H38H37V29H36H35V30H34H33V31H32V32H31V33H30V34H29V33H28V32ZM27 34V35H26V34H27ZM27 34H28V33H27V34ZM37 31H38V32H37V31ZM37 31V30H36V31H37ZM37 39V40H36V39H37ZM37 40H39V41H37V40ZM32 36H33V37H32V36ZM32 36H31V34H32V36ZM33 37H34V38H33V37ZM25 35V36H24V35H25ZM23 38V37H24V39H23V38ZM24 41V39H25V40V41H24ZM25 41H26V42H25V41Z"
				fill={inactive ? theme.grey5 : theme.pokiBlue}
				shapeRendering="crispEdges"
			/>
		</svg>
	);
};

export default BananaEmoji;
