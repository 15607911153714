import dataFormatter from 'app/src/utils/dataFormatter';
import { listGamesByTeamId, getGameById, getGameErrorsPerMinute, getGameEngineAnnotations, listActiveVersionsByGameId, listArchivedVersionsByGameId, listInactiveVersionsByGameId, listAllVersionsByGameId } from 'app/src/epics/game';

const initialState = {
	games: null,
	gamesByTeamId: {},
	errorsPerMinute: {},
	annotations: {},
	allVersionListByGameId: {},
	activeVersionListByGameId: {},
	inactiveVersionListByGameId: {},
	archivedVersionListByGameId: {},
};

// This adds external URLs to Martin Magni's games so the dashboards will show data.
// These games only have external revenue and aren't live anywhere on Poki.
function addMartinMagniException(game) {
	if (game.id === 'c659695e-5ec4-46e2-b515-cc6482b75c20') {
		game.content_metadata.release_status = 'limited-release';
		game.content_metadata.urls['play.fancade.com'] = 'https://play.fancade.com/';
	} else if (game.id === '090b2b7e-3bc9-4b0d-bbe6-d52ae296d6b8') {
		game.content_metadata.release_status = 'limited-release';
		game.content_metadata.urls['play.mekorama.com'] = 'https://play.mekorama.com/';
	}
}

export default function gamesReducer(state = initialState, action) {
	if (action.type === listGamesByTeamId.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;

		const games = dataFormatter.deserialize(response);

		games.forEach(addMartinMagniException);

		return {
			...state,
			gamesByTeamId: {
				...state.gamesByTeamId,
				[action.payload.options.teamId]: {
					data: games,
					links,
					meta,
				},
			},
		};
	}

	if (action.type === getGameById.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;

		const game = dataFormatter.deserialize(response);

		addMartinMagniException(game);

		return {
			...state,
			games: {
				...state.games,

				[game.id]: {
					data: game,
					links,
					meta,
				},
			},
		};
	}

	if (action.type === getGameErrorsPerMinute.success.type) {
		const { options, result } = action.payload;

		return {
			...state,
			errorsPerMinute: {
				...state.errorsPerMinute,

				[options.gameId]: result.response.metrics,
			},
		};
	}

	if (action.type === getGameEngineAnnotations.success.type) {
		const { result: { response } } = action.payload;

		return {
			...state,
			annotations: response,
		};
	}

	if (action.type === listAllVersionsByGameId.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			allVersionListByGameId: {
				...state.allVersionListByGameId,

				[action.payload.options.gameId]: {
					data,
					links,
					meta,
				},
			},
		};
	}

	if (action.type === listActiveVersionsByGameId.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			activeVersionListByGameId: {
				...state.activeVersionListByGameId,

				[action.payload.options.gameId]: {
					data,
					links,
					meta,
				},
			},
		};
	}

	if (action.type === listInactiveVersionsByGameId.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			inactiveVersionListByGameId: {
				...state.inactiveVersionListByGameId,

				[action.payload.options.gameId]: {
					data,
					links,
					meta,
				},
			},
		};
	}

	if (action.type === listArchivedVersionsByGameId.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			archivedVersionListByGameId: {
				...state.archivedVersionListByGameId,

				[action.payload.options.gameId]: {
					data,
					links,
					meta,
				},
			},
		};
	}

	return state;
}
