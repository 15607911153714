import { of, delay, takeUntil, switchMap, map } from 'rxjs';
import { ofType } from 'redux-observable';

import { startScreenShake, stopScreenShake } from 'app/src/actions/effects';

export function screenShakeEpic(action$) {
	const takeUntil$ = action$.pipe(ofType(startScreenShake.type));

	return action$.pipe(
		ofType(startScreenShake.type),
		switchMap(() => of(null).pipe(
			delay(500),
			map(() => stopScreenShake()),
			takeUntil(takeUntil$),
		)),
	);
}
