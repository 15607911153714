import { of, merge, switchMap, map, tap, delay, mergeMap, take, ignoreElements } from 'rxjs';
import { ofType } from 'redux-observable';
import { createApiEpic } from '@poki/rx-api';

import { signout, setRefreshToken, setAccessToken, setTokenTTL, runAfterRefresh, authRefreshed } from 'app/src/actions/session';
import getApiUrl from 'app/src/utils/getApiUrl';
import { pushEvent } from 'app/src/utils/tracking';
import { openModal } from 'app/src/actions/client';

const redirectConfig = {
	production: 'https://developers.poki.com',
	development: 'http://localhost:1234',
	test: 'http://localhost:1234',
};

export const redirectOnSignoutEpic = action$ => (
	action$.pipe(
		ofType(signout.type),
		tap(() => {
			const redirectURL = redirectConfig[process.env.NODE_ENV];
			window.location.href = redirectURL;
		}),
		ignoreElements(),
	)
);

/* API Epics */

export const authorize = createApiEpic(
	'session/authorize',
	(callApi, { exchangeToken }) => callApi({
		url: getApiUrl('auth', 'exchange'),
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ exchange_token: exchangeToken }),
	}),
	({ success$, error$ }) => (
		merge(
			success$.pipe(
				tap(({ payload: { options: { provider } } }) => {
					pushEvent('login', 'successfulLogin', { provider });
				}),
				switchMap(({ payload: { result: { response } } }) => (
					of(
						setAccessToken({ accessToken: response.access_token }),
						setRefreshToken({ refreshToken: response.refresh_token }),
						setTokenTTL({ ttl: response.ttl }),
					)
				)),
			),
			error$.pipe(
				map(() => signout()),
			),
		)
	),
);

export const refreshAuth = createApiEpic(
	'session/refresh',
	(callApi, { refreshToken }) => callApi({
		url: getApiUrl('auth', 'refresh'),
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ refresh_token: refreshToken }),
	}),
	({ success$, error$ }) => (
		merge(
			success$.pipe(
				switchMap(({ payload: { result: { response } } }) => (
					of(
						setAccessToken({ accessToken: response.access_token }),
						setTokenTTL({ ttl: response.ttl }),
						authRefreshed(),
					)
				)),
			),
			error$.pipe(
				switchMap(({ payload: { result: { response }, options = {} } }) => {
					if (response?.error === 'failed to generate new access token') {
						return of(signout());
					}

					let retryCount = options.retryCount || 0;
					if (retryCount < 3) {
						retryCount++;
						return of(refreshAuth.fetch({ ...options, retryCount }))
							.pipe(delay(retryCount * 1000));
					}

					return of(openModal({ key: 'temp-auth-issues' }));
				}),
			),
		)
	),
);

export function runAfterRefreshEpic(action$, state$) {
	return action$.pipe(
		ofType(runAfterRefresh.type),
		mergeMap(({ handler }) => (
			action$.pipe(
				ofType(authRefreshed.type),
				take(1),
				mergeMap(() => handler(state$)),
			)
		)),
	);
}
