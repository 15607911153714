import { createAuthorizedApiEpic } from 'app/src/utils/api';
import getApiUrl from 'app/src/utils/getApiUrl';
import dataFormatter from 'app/src/utils/dataFormatter';

export const markPlaytestWatched = createAuthorizedApiEpic(
	'game/mark_playtest_watched',
	(callApi, { gameId, playtestId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/playtest-recordings/${playtestId}/@watch`),
		method: 'POST',
	}),
);

export const getPlaytestRecordingsByGameId = createAuthorizedApiEpic(
	'game/get_playtest_recordings_for_game',
	(callApi, { gameId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/playtest-recordings`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const requestPlaytestRecordings = createAuthorizedApiEpic(
	'game/request_playtest_recordings',
	(callApi, { gameId, data }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/playtest-requests`),
		method: 'POST',
		headers: {
			'Content-Type': 'application/vnd.api+json',
			Accept: 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				type: 'playtest_requests',
				...data,
			},
		})),
	}),
);

export const deletePlaytestRequest = createAuthorizedApiEpic(
	'game/delete_playtest_request',
	(callApi, { gameId, playtestRequestId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/playtest-requests/${playtestRequestId}`),
		method: 'DELETE',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const patchPlaytestRecording = createAuthorizedApiEpic(
	'game/patch_playtest_recording',
	(callApi, { gameId, recordingId, data }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/playtest-recordings/${recordingId}`),
		method: 'PATCH',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				type: 'playtest_recordings',
				...data,
			},
		})),
	}),
);
