import { listPendingInvoices, listInvoicesForTeam, listInvoicesForYearMonth, listAllPendingInvoices, listAllInvoicesForYearMonth } from 'app/src/epics/invoice';
import dataFormatter from 'app/src/utils/dataFormatter';

const initialState = {
	pendingInvoices: null,
	allPendingInvoices: null,
	invoicesForTeam: {},
	invoicesForYearMonth: {},
	allInvoicesForYearMonth: {},
};

const listInvoicesForYearMonthValues = Object.values(listInvoicesForYearMonth);
const listAllInvoicesForYearMonthValues = Object.values(listAllInvoicesForYearMonth);

export default function invoiceReducer(state = initialState, action) {
	if (action.type === listPendingInvoices.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;

		return {
			...state,
			pendingInvoices: {
				data: dataFormatter.deserialize(response),
				links,
				meta,
			},
		};
	}

	if (action.type === listAllPendingInvoices.success.type) {
		const { response } = action.payload.result;

		return {
			...state,
			allPendingInvoices: {
				data: dataFormatter.deserialize(response),
			},
		};
	}

	if (action.type === listInvoicesForTeam.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;

		return {
			...state,
			invoicesForTeam: {
				...state.invoicesForTeam,

				[action.payload.options.teamId]: {
					data: dataFormatter.deserialize(response),
					links,
					meta,
				},
			},
		};
	}

	for (let i = 0; i < listInvoicesForYearMonthValues.length; i++) {
		const epic = listInvoicesForYearMonthValues[i];
		if (action.type === epic.success.type) {
			const { response } = action.payload.result;
			const { links, meta } = response;
			const { year, month } = action.payload.options;

			return {
				...state,
				invoicesForYearMonth: {
					...state.invoicesForYearMonth,

					[`${year}-${month}`]: {
						data: dataFormatter.deserialize(response),
						links,
						meta,
					},
				},
			};
		}
	}

	for (let i = 0; i < listAllInvoicesForYearMonthValues.length; i++) {
		const epic = listAllInvoicesForYearMonthValues[i];
		if (action.type === epic.success.type) {
			const { response } = action.payload.result;
			const { links, meta } = response;
			const { year, month } = action.payload.options;

			return {
				...state,
				allInvoicesForYearMonth: {
					...state.allInvoicesForYearMonth,

					[`${year}-${month}`]: {
						data: dataFormatter.deserialize(response),
						links,
						meta,
					},
				},
			};
		}
	}

	return state;
}
