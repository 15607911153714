import React, { useState } from 'react';
import styled from 'styled-components';

import { BackgroundImage, Container, H1, P, Page, SectionContainer } from 'site/src/components/styled';
import MarketingHeader from 'site/src/components/MarketingHeader';
import GameSubmissionForm from 'site/src/components/GameSubmissionForm';
import MarketingFooter from 'shared/components/MarketingFooter';
import ThankYouMessage from 'site/src/components/ThankYouMessage';
import Card from 'app/src/components/ui/Card';

import { _medium, _small } from 'shared/vars';

const StyledSectionContainer = styled(SectionContainer)`
	max-width: 780px;
	padding-top: 160px;

	${_medium} {
		padding-top: 220px;
		padding-bottom: 80px;
	}
`;

const StyledBackgroundImage = styled(BackgroundImage)`
	position: absolute;
	top: 0;
	left: 0;
	min-height: 750px;
	background-position-y: bottom;
`;

const TitleContainer = styled.div`
	padding: 0 18px;
	margin-bottom: 24px;

	${_small} {
		text-align: center;
		margin-bottom: 48px;
	}
`;

const StyledH1 = styled(H1)`
	font-size: 32px;
	margin-bottom: 16px;

	${_small} {
		font-size: 52px;
	}
`;

const Paragraph = styled(P)`
	line-height: 28px;
`;

const StyledLink = styled.a`
	font-size: 14px;
	font-weight: 700;
`;

const PrivacyPolicy = styled.div`
	margin: 24px 0 0;
    padding: 16px;
	line-height: 18px;

	color: ${props => props.theme.grey3};
`;

const ShareGamePage = () => {
	const [showThankYouPage, setShowThankYouPage] = useState(false);

	const callbackOnSuccess = () => {
		setShowThankYouPage(true);
	};

	return (
		<Container>
			{showThankYouPage ? (
				<BackgroundImage center backgroundUrl="/images/hero-bg-hearts-small.svg" />
			) : (
				<StyledBackgroundImage center backgroundUrl="/images/hero-bg-hearts.svg" />
			)}
			<MarketingHeader />
			<Page>
				{showThankYouPage ? (
					<ThankYouMessage />
				) : (
					<StyledSectionContainer>
						<TitleContainer>
							<StyledH1>Request early access by sharing your game</StyledH1>
							<Paragraph>
								We&apos;re always on the lookout for developers to launch, improve, and skyrocket games to success 🚀.
							</Paragraph>
							<Paragraph>
								Share a (playable) link to your game and we&apos;ll let you know if we can give you early access to Poki for Developers.
							</Paragraph>
						</TitleContainer>
						<Card>
							<GameSubmissionForm callbackOnSuccess={callbackOnSuccess} />
						</Card>
						<PrivacyPolicy>
							<Paragraph $small>
								For information on processing of your personal data in the context of this web form, please refer to <StyledLink href="https://poki.com/en/c/global-privacy-statement" target="_blank">Poki’s Website Privacy Statement – EEA.</StyledLink>
							</Paragraph>
						</PrivacyPolicy>
					</StyledSectionContainer>
				)}
				<MarketingFooter small />
			</Page>
		</Container>
	);
};

export default ShareGamePage;
