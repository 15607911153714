import { clearData } from 'app/src/actions/data';
import * as dataEpics from 'app/src/epics/data';
import flattenEpics from 'app/src/utils/flatten';

const epics = flattenEpics(dataEpics);

const initialState = {};
epics.forEach(epic => {
	initialState[epic.id] = {};
});

const dynamicEpics = [];

export const addDynamicDataEpic = epic => {
	dynamicEpics.push(epic);
};

export const removeDynamicDataEpic = epic => {
	dynamicEpics.splice(dynamicEpics.find(e => epic.id === e.id), 1);
};

export default function dataReducer(state = initialState, action) {
	const successEpic = epics.find(e => action.type === e.success.type) || dynamicEpics.find(e => action.type === e.success.type);
	const fetchEpic = epics.find(e => action.type === e.fetch.type) || dynamicEpics.find(e => action.type === e.fetch.type);

	if (fetchEpic) {
		return {
			...state,
			[fetchEpic.id]: null,
		};
	} else if (successEpic) {
		return {
			...state,
			[successEpic.id]: action.payload.result.response,
		};
	} else if (action === clearData.type) {
		return { ...initialState };
	}

	return state;
}
