const isDevelopment = process.env.NODE_ENV === 'development';

export default function getCDNImage({ src, width, height }) {
	if (isDevelopment) {
		if (width && !height) {
			return `${src}?w=${width}`;
		}

		return `${src}?w=${width}&h=${height}`;
	}

	return `https://developers.poki.com/cdn-cgi/image/width=${width}${!height ? '' : `,height=${height}`},quality=75,format=auto${src}`;
}
