import React, { useEffect, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';

import CopyToClipboard from 'app/src/components/CopyToClipboard';

import { motionSpeed02, motionOut } from 'shared/vars';
import _ from 'shared/copy';

const StyledInput = styled.input`
	display: block;
	padding: 0 16px;
	line-height: 48px;
	height: 48px;
	width: 100%;
	background: ${props => props.theme.grey7};
	color: ${props => props.theme.denimBlue};
	font-size: 16px;
	border: 1px solid ${props => props.theme.grey5};
	transition: background ${motionSpeed02} ${motionOut}, color ${motionSpeed02} ${motionOut};
	border-radius: 8px;

	${props => props.small && `
	height: 36px;
	line-height: 36px;
	font-size: 14px;
	padding: 0 40px 0 16px;
	`}

	::placeholder {
		color: ${props => props.theme.grey3};
	}

	&:focus {
		outline: none;
		border-color: ${props => props.theme.pokiBlue};
		box-shadow: inset 0 0 0 2px ${props => props.theme.pokiBlue};
		background: ${props => props.theme.grey9};

		${props => props.small && `
		padding: 0 38px 0 14px;
		line-height: 32px;
		`}
	}

	${props => (props.disabled ? `
	color: ${props.theme.grey3};
	cursor: not-allowed;
	border: none;
	padding: 0 17px;

	${props.small && `
		padding: 0 41px 0 17px;
	`}
	` : `
	&:hover {
		background: ${props.theme.grey9};
	}
	`)}

	${props => props.error && `
	border-color: ${props.theme.rose1};
	background: ${props.theme.isDarkMode ? props.theme.static.rose1 : props.theme.static.rose9};
	`}

	${props => props.valid && `
	border-color: ${props.theme.green1};
	background: ${props.theme.green9};
	color: ${props.theme.static.denimBlue};

	&:hover {
		color: ${props.theme.denimBlue};
	}

	::placeholder {
		color: ${props.theme.static.grey3};
	}
	`}

	${props => props.allowCopy && `
		padding-right: 36px;
		text-overflow: ellipsis;
	`}
`;

const InputContainer = styled.div`
	position: relative;
	margin-bottom: 8px;

	> svg {
		position: absolute;
		top: 11px;
		left: 11px;
		pointer-events: none;
	}

	${props => props.small && `
	margin-bottom: 0;

	> svg {
		top: 6px;
	}
	`}

	> svg + ${StyledInput} {
		padding-left: 44px;
	}

	> svg [fill] {
		fill: ${props => props.theme.grey3};
	}

	&:focus-within {
		> svg [fill] {
			fill: ${props => props.theme.pokiBlue};
		}
	}
`;

const StyledFieldset = styled.fieldset`
	position: relative;
	min-width: 0;
	max-width: 500px;
	margin-bottom: 16px;
`;

const StyledLabel = styled.label`
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.grey3};

	${StyledInput} {
		margin-top: 4px;
	}
`;

const Description = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;
	min-height: 18px;

	${props => props.shortDesc && `
	min-height: 18px;
	text-align: right;
	margin-top: 4px;
	`}
`;

const Error = styled.div`
	color: ${props => props.theme.rose1};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;

	& + & {
		margin-top: 0;
	}
`;

const Prefix = styled.div`
	position: absolute;
	pointer-events: none;
	left: 0;
	top: 0;
	padding: 0 0 0 16px;
	font-weight: 400;
	height: 48px;
	line-height: 48px;
	font-size: 16px;
`;

const Required = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	position: absolute;
	top: 4px;
	right: 0;
`;

const StyledCopyToClipboard = styled(CopyToClipboard)`
	top: 50%;
	right: 16px;
	transform: translateY(-50%);

	&& {
		position: absolute; // Override the default position: relative on title objects
	}

	svg {
		[fill] {
			fill: ${props => props.theme.grey3};
		}
	}
`;

const TextInput = props => {
	const { autoFocus, className, name, prefix, small, errors = [], description, descriptionHTML, disabled, icon: Icon, placeholder, label, value, valueSetter, onChange, maxLength, shortDesc, focus$, onBlur, valid, required, readonly, autoSelect, type = 'text', allowCopy = false } = props;

	const handleChange = evt => {
		if (valueSetter) valueSetter(evt.target.value);
		if (onChange) onChange(evt);
	};

	const prefixRef = useRef();
	const inputRef = useRef();

	const input = (
		<InputContainer small={small}>
			{Icon && <Icon />}
			<StyledInput
				ref={inputRef}
				id={name}
				type={type}
				autoFocus={!!autoFocus}
				name={name}
				disabled={disabled}
				readonly={readonly}
				placeholder={placeholder}
				value={value}
				onChange={handleChange}
				maxLength={maxLength}
				onBlur={onBlur}
				error={errors.length > 0}
				valid={valid}
				small={small}
				allowCopy={allowCopy}
			/>
			{prefix && <Prefix ref={prefixRef}>{prefix}</Prefix>}
			{allowCopy && <StyledCopyToClipboard text={value} />}
		</InputContainer>
	);

	useLayoutEffect(() => {
		if (!prefixRef.current || !inputRef.current) return;

		inputRef.current.style.paddingLeft = `${prefixRef.current.getBoundingClientRect().width + 4}px`;
	}, [prefixRef, inputRef]);

	useEffect(() => {
		if (focus$) {
			focus$.subscribe(() => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			});
		}

		return () => {
			if (focus$) {
				focus$.unsubscribe();
			}
		};
	}, []);

	useEffect(() => {
		if (autoSelect) {
			const select = () => inputRef.current.select();

			inputRef.current.addEventListener('focus', select);

			return () => {
				if (inputRef.current) {
					inputRef.current.removeEventListener('focus', select);
				}
			};
		}
	}, [autoSelect]);

	return (
		<StyledFieldset className={className}>
			{required && <Required>{_`required`}</Required>}
			{label ? (
				<StyledLabel htmlFor={name} error={errors.length > 0} valid={valid}>{label}{input}</StyledLabel>
			) : input}
			{errors.length > 0 ? errors.map(err => <Error key={err}>{err}</Error>) : (
				description && (
					descriptionHTML ? (
						<Description shortDesc={shortDesc} dangerouslySetInnerHTML={{ __html: description }} />
					) : (
						<Description shortDesc={shortDesc}>{description}</Description>
					)
				)
			)}
		</StyledFieldset>
	);
};

export default TextInput;
