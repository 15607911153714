import { listDomainCollections } from 'app/src/epics/domains';
import { getGameAllowList } from 'app/src/epics/game';

export const initialState = {
	domainCollections: [],
	allowList: {},
};

export default function domainsReducer(state = initialState, action) {
	if (action.type === listDomainCollections.success.type) {
		const { payload: { result: { response } } } = action;

		return {
			...state,

			domainCollections: Object.keys(response).map(name => ({
				name,
				domains: response[name],
			})),
		};
	}

	if (action.type === getGameAllowList.success.type) {
		const { payload: { result: { response }, options: { gameId } } } = action;

		return {
			...state,

			allowList: {
				...state.allowList,

				[gameId]: response,
			},
		};
	}

	return state;
}
