import React from 'react';

import { useSelectTheme } from 'app/src/selectors/session';

const UnicornEmoji = props => {
	const { className, inactive } = props;
	const theme = useSelectTheme();

	return (
		<svg className={className} width="128" height="128" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18 15H19V16H20V17H21V18H22V19H23V20H24V21H25V22H27V23H28V21H29V17H30V18H31V19H32V20H33V21H34V17H35V18H36V19H37V20H38V21H42V23H45V26H46V28H48V41H47V42H46V43H45V44H44V45H39V44H38V42H37V40H36V36H35V38H34V39H33V40H29V41H27V42H25V43H20V41H19V36H20V34H21V33H22V31H23V30H24V28H25V27H26V26H25V25H24V23H23V22H22V20H21V19H20V18H19V17H18V15ZM38 27V30H39V33H40V35H41V33H40V30H39V27H38ZM29 28V29H28V30H29V31H30V30H31V29H30V28H29ZM43 30V34H44V39H45V34H44V30H43ZM21 36V38H24V37H22V36H21Z"
				fill={inactive ? theme.grey5 : theme.pokiBlue}
				shapeRendering="crispEdges"
			/>
		</svg>
	);
};

export default UnicornEmoji;
