const measures = {};

// Totals

measures.conversion_to_play = {
	calculate: row => row.daily_playing_users / row.daily_active_users,
	formula: {
		operator: '/',
		terms: [
			{
				aggregate: 'sum',
				field: 'daily_playing_users',
			},
			{
				aggregate: 'sum',
				field: 'daily_active_users',
			},
		],
	},
};

measures.time_spent = {
	calculate: row => (row.play_time + row.pre_play_time + row.video_ad_visible_time),
	formula: {
		operator: '+',
		terms: [
			{
				aggregate: 'sum',
				field: 'pre_play_time',
			},
			{
				aggregate: 'sum',
				field: 'play_time',
			},
			{
				aggregate: 'sum',
				field: 'video_ad_visible_time',
			},
		],
	},
};

measures.ads = {
	calculate: row => row.ingame_display_impressions + row.gamebar_display_impressions + row.platform_display_impressions + row.preroll_video_impressions + row.midroll_video_impressions + row.rewarded_video_impressions,
	formula: {
		operator: '+',
		terms: [
			{
				aggregate: 'sum',
				field: 'ingame_display_impressions',
			},
			{
				aggregate: 'sum',
				field: 'gamebar_display_impressions',
			},
			{
				aggregate: 'sum',
				field: 'platform_display_impressions',
			},
			{
				aggregate: 'sum',
				field: 'preroll_video_impressions',
			},
			{
				aggregate: 'sum',
				field: 'midroll_video_impressions',
			},
			{
				aggregate: 'sum',
				field: 'rewarded_video_impressions',
			},
		],
	},
};

// Groupings

measures.time_spent_per_dau = {
	calculate: row => measures.time_spent.calculate(row) / row.daily_active_users,
	formula: {
		operator: '/',
		terms: [
			{
				formula: measures.time_spent.formula,
			},
			{
				aggregate: 'sum',
				field: 'daily_active_users',
			},
		],
	},
};

measures.ads_per_dau = {
	calculate: row => measures.ads.calculate(row) / row.daily_active_users,
	formula: {
		operator: '/',
		terms: [
			{
				formula: measures.ads.formula,
			},
			{
				aggregate: 'sum',
				field: 'daily_active_users',
			},
		],
	},
};

measures.gameplays_per_day = {
	formula: {
		operator: '/',
		terms: [
			{
				aggregate: 'sum',
				field: 'gameplays',
			},
			{
				aggregate: 'count',
				distinct: true,
				field: 'date',
			},
		],
	},
};

measures.developer_earnings_eur_per_day = {
	formula: {
		operator: '/',
		terms: [
			{
				aggregate: 'sum',
				field: 'developer_earnings_eur',
			},
			{
				aggregate: 'count',
				distinct: true,
				field: 'date',
			},
		],
	},
};

measures.developer_earnings_usd_per_day = {
	formula: {
		operator: '/',
		terms: [
			{
				aggregate: 'sum',
				field: 'developer_earnings_usd',
			},
			{
				aggregate: 'count',
				distinct: true,
				field: 'date',
			},
		],
	},
};

export default measures;
