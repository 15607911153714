import { setViewport, openModal, closeModal, openToast, closeToast, toggleNavigation, toggleDateRangeInput, preventPageLeave, addPermalinkKeyValue, clearPermalinkKeyValues, toggleIsDragging } from 'app/src/actions/client';
import { isMobile } from 'shared/vars';

export const initialState = {
	viewport: { width: 0, height: 0 },
	modal: null,
	navigationActive: !isMobile,
	isDateRangeInputOpen: false,
	toasts: [],
	preventPageLeave: false,
	permalinkKeyValues: {},
};

export default function clientReducer(state = initialState, action) {
	if (action.type === setViewport.type) {
		return {
			...state,
			viewport: {
				height: action.height,
				width: action.width,
			},
		};
	}

	if (action.type === openModal.type) {
		const { mustCloseOnLocationChanged = true } = action;

		return {
			...state,
			modal: {
				key: action.key,
				mustCloseOnLocationChanged,
				data: action.data,
			},
		};
	}

	if (action.type === closeModal.type) {
		return {
			...state,
			modal: null,
		};
	}

	if (action.type === toggleNavigation.type) {
		return {
			...state,
			navigationActive: typeof action.force !== 'undefined' ? action.force : !state.navigationActive,
		};
	}

	if (action.type === toggleDateRangeInput.type) {
		return {
			...state,
			isDateRangeInputOpen: action.toggle,
		};
	}

	if (action.type === toggleIsDragging.type) {
		return {
			...state,
			isDragging: action.toggle,
		};
	}

	if (action.type === openToast.type) {
		// Find first free index
		let idx;
		for (idx = 0; idx < Infinity; idx++) {
			if (!state.toasts[idx]) break;
		}

		const newToasts = [...state.toasts];
		newToasts[idx] = {
			index: idx,
			body: action.body,
			type: action.toastType,
		};

		return {
			...state,
			toasts: newToasts,
		};
	}

	if (action.type === closeToast.type) {
		const remainingToasts = [...state.toasts];
		remainingToasts[action.index] = null;

		return {
			...state,
			toasts: remainingToasts,
		};
	}

	if (action.type === preventPageLeave.type) {
		return {
			...state,
			preventPageLeave: !!action.toggle,
		};
	}

	if (action.type === clearPermalinkKeyValues.type) {
		return {
			...state,
			permalinkKeyValues: {},
		};
	}

	if (action.type === addPermalinkKeyValue.type) {
		return {
			...state,
			permalinkKeyValues: {
				...state.permalinkKeyValues,
				[action.key]: action.value,
			},
		};
	}

	return state;
}
