import React, { useState } from 'react';
import { Subject } from 'rxjs';
import styled from 'styled-components';

import DocumentIcon from 'shared/designTokens/icons/ui/small/DocumentIcon';
import Modal from 'app/src/components/ui/Modal';
import Table from 'app/src/components/ui/Table';
import { registerModal } from 'app/src/modals';
import { customScrollbar, lightColors } from 'shared/vars';

export const StyledModal = styled(Modal)`
	padding: 32px 0px 8px;
	max-height: 665px;
	max-width: 625px;
	width: 100%;
	overflow-y: auto;
	${customScrollbar}
`;

const TitleWrapper = styled.div`
	padding: 0 16px;
`;

const Title = styled.h2`
	margin: 0 0 6px;
`;

const Subtitle = styled.div`
	display: flex;
	align-items: center;
	margin: 0 0 16px;
	color: ${lightColors.dataGrey3};

	& svg {
		width: 18px;
		margin-right: 6px;

		[fill] {
			fill: ${lightColors.dataGrey3};
		}
	}
`;

const ViewRawDataModal = props => {
	const { data: { title, items, columns } } = props;

	const [exit$] = useState(new Subject());

	return (
		<StyledModal exit$={exit$}>
			<TitleWrapper>
				<Title>{title}</Title>
				<Subtitle>
					<DocumentIcon />
					Raw data
				</Subtitle>
			</TitleWrapper>
			<Table
				items={items}
				columns={columns}
			/>
		</StyledModal>
	);
};

registerModal('view-raw-data', ViewRawDataModal);
