import React from 'react';
import styled from 'styled-components';

import ChevronDownIcon from 'shared/designTokens/icons/ui/small/ChevronDownIcon';

import { motionSpeed02, motionOut } from 'shared/vars';
import _ from 'shared/copy';

const StyledSelect = styled.select`
	appearance: none;
	background: ${props => props.theme.grey7};
	border: 1px solid ${props => props.theme.grey5};
	font-size: 16px;
	color: ${props => props.theme.denimBlue};
	padding: 0 56px 0 16px;
	line-height: 48px;
	height: 48px;
	width: 100%;
	border-radius: 8px;
	transition: background ${motionSpeed02} ${motionOut}, color ${motionSpeed02} ${motionOut};
	font-family: 'Proxima Nova';

	${props => props.isHighlighted && `
		font-weight: bold;
		color: ${props.theme.pokiBlue};
	`}

	${props => props.small && `
	height: 36px;
	line-height: 36px;
	font-size: 14px;
	padding: 0 40px 0 16px;
	`}

	${props => props.isPlaceholder && `
	color: ${props.theme.grey3};
	`}

	&:focus {
		outline: none;
		border: 3px solid ${props => props.theme.pokiBlue};
		padding: 0 54px 0 14px;
		background-color: ${props => props.theme.grey9};
		line-height: 44px;

		${props => props.small && `
		padding: 0 38px 0 14px;
		line-height: 32px;
		`}
	}

	${props => (props.disabled ? `
	color: ${props.theme.grey3};
	cursor: not-allowed;
	border: none;
	padding: 0 57px 0 17px;
	` : `
	&:hover {
		background-color: ${props.theme.grey9};
	}

	${props.small && `
	padding: 0 41px 0 17px;
	`}
	`)}

	${props => props.error && `
	border-color: ${props.theme.rose1};
	background-color: ${props.theme.isDarkMode ? props.theme.static.rose1 : props.theme.static.rose9};
	`}
`;

const SelectContainer = styled.div`
	position: relative;
	margin-bottom: 8px;

	${props => props.small && `
	margin-bottom: 0;
	`}

	&:focus-within path {
		fill: ${props => props.theme.pokiBlue};
	}
`;

const StyledChevronDownIcon = styled(ChevronDownIcon)`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 14px;
	pointer-events: none;

	[fill] {
		fill: ${props => props.theme.grey3};
	}
`;

const StyledLabel = styled.label`
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.grey3};

	${SelectContainer} {
		margin-top: 4px;
	}
`;

const StyledFieldset = styled.fieldset`
	position: relative;
	min-width: 0;
	max-width: 500px;
	margin-bottom: 16px;

	${props => props.small && `
	margin-bottom: 0;
	`}
`;

const Description = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	min-height: 18px;
`;

const Error = styled.div`
	color: ${props => props.theme.rose1};
	font-size: 14px;
	line-height: 18px;

	& + & {
		margin-top: 0;
	}
`;

const Required = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	position: absolute;
	top: 4px;
	right: 0;
`;

const SelectInput = props => {
	const { name, className, errors = [], description, disabled, label, placeholder, value, valueSetter, values, onChange, required, small, isHighlighted } = props;

	const handleChange = evt => {
		if (valueSetter) valueSetter(evt.target.value);
		if (onChange) onChange(evt);
	};

	const input = (
		<SelectContainer small={small}>
			<StyledSelect
				name={name}
				disabled={disabled}
				error={errors.length > 0}
				onChange={handleChange}
				value={typeof value !== 'undefined' && value !== null ? value : ''}
				isPlaceholder={typeof value === 'undefined' || value === '' || value === null}
				isHighlighted={isHighlighted}
				small={small}
			>
				{placeholder && (
					<>
						<option value="">{placeholder}</option>
						<option /> {/* eslint-disable-line jsx-a11y/control-has-associated-label */}
					</>
				)}
				{values.map(({ value: val, desc }) => (
					<option key={val} value={val}>{desc}</option>
				))}
			</StyledSelect>
			<StyledChevronDownIcon />
		</SelectContainer>
	);

	return (
		<StyledFieldset className={className} small={small}>
			{required && <Required>{_`required`}</Required>}
			{label ? (
				<StyledLabel htmlFor={name}>{label}{input}</StyledLabel>
			) : input}
			{errors.length > 0 ? errors.map(err => <Error key={err}>{err}</Error>) : (
				description && (
					<Description>{description}</Description>
				)
			)}
		</StyledFieldset>
	);
};

export default SelectInput;
