import { createAuthorizedApiEpic } from 'app/src/utils/api';
import getApiUrl from 'app/src/utils/getApiUrl';

export const listDomainCollections = createAuthorizedApiEpic(
	'domains/list_domain_collections',
	callApi => callApi({
		url: getApiUrl('devs', 'domain-collections'),
		method: 'GET',
		headers: { Accept: 'application/json' },
	}),
);

export const createDomainCollection = createAuthorizedApiEpic(
	'domains/create_domain_collection',
	(callApi, { name, domains }) => callApi({
		url: getApiUrl('devs', 'domain-collections'),
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: {
			name,
			domains,
		},
	}),
);

export const patchDomainCollection = createAuthorizedApiEpic(
	'domains/patch_domain_collection',
	(callApi, { name, domains }) => callApi({
		url: getApiUrl('devs', `domain-collections/${name}`),
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: {
			domains,
		},
	}),
);
