import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { Container, BackgroundImage, Page, H3, Hr, Paragraph } from 'site/src/components/styled';
import MarketingHeader from 'site/src/components/MarketingHeader';
import ArrowLeftIcon from 'shared/designTokens/icons/ui/small/ArrowLeftIcon';
import MobileCheckIcon from 'shared/components/svg/MobileCheck';
import UsersIcon from 'shared/designTokens/icons/ui/medium/UsersIcon';
import { ParentDirectory, Main, Navigation, Title, IconContainer, SubSubTitle } from 'site/src/components/guide.styled';
import SignUpCTA from 'site/src/components/SignUpCTA';
import GameEngine from 'site/src/components/GameEngine';
import MarketingFooter from 'shared/components/MarketingFooter';
import { _medium } from 'shared/vars';
import getCDNImage from 'site/src/utils/getCDNImage';

import cocos from 'site/src/components/pages/engines/cocos.json';
import construct from 'site/src/components/pages/engines/construct.json';
import defold from 'site/src/components/pages/engines/defold.json';
import gamemaker from 'site/src/components/pages/engines/gamemaker.json';
import godot from 'site/src/components/pages/engines/godot.json';
import layaair from 'site/src/components/pages/engines/layaair.json';
import phaser from 'site/src/components/pages/engines/phaser.json';
import pixi from 'site/src/components/pages/engines/pixi.json';
import playcanvas from 'site/src/components/pages/engines/playcanvas.json';
import stencyl from 'site/src/components/pages/engines/stencyl.json';
import threeJs from 'site/src/components/pages/engines/three-js.json';
import unity from 'site/src/components/pages/engines/unity.json';

const engines = {
	cocos: {
		...cocos,
		image: getCDNImage({ src: '/engine_logos/engine-cocos.png', width: 300 }),
	},
	construct: {
		...construct,
		image: getCDNImage({ src: '/images/guide/engine_logos/Engine-construct.png', width: 300 }),
	},
	defold: {
		...defold,
		image: getCDNImage({ src: '/images/guide/engine_logos/Engine-defold.png', width: 300 }),
	},
	gamemaker: {
		...gamemaker,
		image: getCDNImage({ src: '/images/guide/engine_logos/engine-gamemaker.png', width: 300 }),
	},
	godot: {
		...godot,
		image: getCDNImage({ src: '/images/guide/engine_logos/Engine-godot.png', width: 300 }),
	},
	layaair: {
		...layaair,
		image: getCDNImage({ src: '/images/guide/engine_logos/Engine-layaair.png', width: 300 }),
	},
	phaser: {
		...phaser,
		image: getCDNImage({ src: '/images/guide/engine_logos/Engine-phaser.png', width: 300 }),
	},
	pixi: {
		...pixi,
		image: getCDNImage({ src: '/images/guide/engine_logos/Engine-pixi.png', width: 300 }),
	},
	playcanvas: {
		...playcanvas,
		image: getCDNImage({ src: '/images/guide/engine_logos/Engine-playcanvas.png', width: 300 }),
	},
	stencyl: {
		...stencyl,
		image: getCDNImage({ src: '/images/guide/engine_logos/Engine-stencyl.png', width: 300 }),
	},
	'three-js': {
		...threeJs,
		image: getCDNImage({ src: '/images/guide/engine_logos/Engine-three-js.png', width: 300 }),
	},
	unity: {
		...unity,
		image: getCDNImage({ src: '/images/guide/engine_logos/Engine-unity.png', width: 300 }),
	},
};

const Par = styled(Paragraph)`
	margin-bottom: 56px;

	${_medium} {
		margin-bottom: 40px;
	}

	strong {
		color: inherit;
	}
`;

const GameEngineLink = styled.a`
	border-radius: 40px;
	background-color: ${props => props.theme.grey7};
	display: inline-block;
	padding: 4px 16px;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
`;

const GameEngineList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 8px 0;
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
`;

const TopicList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
`;

const Topic = styled.li`
	display: flex;
	align-items: flex-start;
	gap: 16px;
	flex-direction: column;
	align-items: center;

	${_medium} {
		flex-direction: row;
		gap: 24px;
		align-items: flex-start;
	}

	${Par} {
		text-align: center;

		${_medium} {
			text-align: left;
		}
	}
`;

const TopicTitle = styled(H3)`
	margin-top: 0 !important;
	text-align: center;
	margin-bottom: 8px;
	font-size: 22px;
	line-height: 127.273%;
	font-family: "Proxima Nova", sans-serif;

	${_medium} {
		text-align: left;
		line-height: 163.636%;
	}
`;

const GuideWebEnginePage = () => (
	<Container>
		<Helmet key="GuideContentPage">
			<title>Poki for Developers - Web game engines</title>
		</Helmet>
		<BackgroundImage center backgroundUrl="/images/header_diamond.svg" />
		<MarketingHeader />
		<Page>
			<ParentDirectory href="/guide"><ArrowLeftIcon />Overview</ParentDirectory>
			<Main>
				<Title>Choosing your web game engine</Title>
				<Par extraLarge>
					One of the first decisions you have to make when making a game is the tech. For web, there are tons of options to choose from. HTML5 technology is much stronger for web games and many of the following engines are purpose built for web compared to Unity whose web export needs work. There are frameworks which are for more experienced developers but can provide amazing results both visually and technically. We have games from all different engines on Poki, so we want to share some info to help you choose which engine fits you best for web!
				</Par>
				<Par large>
					There’s a lot of different things to factor in when choosing an engine, but we wanted to keep the focus on web specifically. The following are some of the most important things to keep in mind when choosing an engine for web!
				</Par>
				<TopicList>
					<Topic>
						<IconContainer backgroundColor="#FBE756">
							<MobileCheckIcon />
						</IconContainer>
						<div>
							<TopicTitle>(Mobile) web tech fit</TopicTitle>
							<Par large>
								Some engines are optimised for web, others support it in some way or another.
								File size is important; for a good web game the initial download should not exceed 5MB and 8MB in total. Knowing that, we compared the <strong>compressed size of an empty project</strong>. Second comes the <strong>support for touch</strong> on mobile. Without that you automatically miss out on a large proportion of players given the popularity of mobile gaming.
							</Par>
						</div>
					</Topic>
					<Topic>
						<IconContainer backgroundColor="#EAC0FC">
							<MobileCheckIcon />
						</IconContainer>
						<div>
							<TopicTitle>What you need in an engine</TopicTitle>
							<Par large>
								This means the capabilities you need for the type of games you create. Basically, can you do what you want to do with this engine? The main differentiators we took are <strong>2D/3D</strong> and <strong>multiplayer</strong> support. We haven’t included all capabilities so use these first to create your shortlist and then you can follow up with more in-depth info!
							</Par>
						</div>
					</Topic>
					<Topic>
						<IconContainer backgroundColor="#83FFE7">
							<UsersIcon />
						</IconContainer>
						<div>
							<TopicTitle>Developer (team) fit</TopicTitle>
							<Par large>
								If you work in a team, you’re probably <strong>working together on the same project</strong>. The game engine you choose needs to support working together on the same project file. Having more people in a team also is a multiplier for costs which is why we included <strong>licensing fees</strong>.
							</Par>
						</div>
					</Topic>
				</TopicList>
				<SubSubTitle>The web game engines discussed in this article</SubSubTitle>
				<GameEngineList>
					{Object.entries(engines).map(([key, engine]) => (
						<li key={key}><GameEngineLink href={`#${key}`}>{engine.engine}{engine['official-partner'] ? ' 🫶' : ''}</GameEngineLink></li>
					))}
				</GameEngineList>
				<Hr />
				{Object.entries(engines).map(([key, engine], i, engineList) => (
					<Fragment key={key}>
						<GameEngine data={engine} id={key} />
						{i < engineList.length - 1 && <Hr />}
					</Fragment>
				))}
			</Main>
			<Navigation
				nextLink="/guide/player-device-report"
				nextLabel="Player Device Report"
			/>
			<SignUpCTA />
			<MarketingFooter />
		</Page>
	</Container>
);

export default GuideWebEnginePage;
