import React, { useRef, useState, useEffect, useLayoutEffect, useMemo } from 'react';
import styled from 'styled-components';
import { fromEvent, tap } from 'rxjs';
import lodash from 'lodash';

import CheckIcon from 'shared/designTokens/icons/ui/tiny/CheckIcon';
import SearchIcon from 'shared/designTokens/icons/ui/small/SearchIcon';

import Card from 'app/src/components/ui/Card';
import TextInput from 'app/src/components/input/TextInput';
import { customScrollbar, isMobile } from 'shared/vars';
import _ from 'shared/copy';

const StyledFieldset = styled.fieldset`
	position: relative;
	min-width: 0;
	max-width: 500px;
	height: max-content;
`;

const ListItemCard = styled(Card)`
	min-width: 200px;
	width: max-content;
	padding: 4px;
	padding-bottom: 12px;
	position: absolute;
	bottom: -8px;
	z-index: 1;
	border: 4px solid ${props => props.theme.pureWhite}; ${''/* Border-based padding for nicer overflow effect */}
	transform: translateY(100%);

	${props => (props.align === 'right' ? `
	right: 0;
	` : `
	left: 0;
	`)}
`;

const Items = styled.div`
	max-height: 400px;
	overflow: auto;

	${customScrollbar}
`;

const StyledCheckIcon = styled(CheckIcon)`
	display: none;

	[fill] {
		fill: ${props => props.theme.pureWhite};
	}
`;

const CheckBox = styled.div`
	position: relative;
	width: 16px;
	height: 16px;
	background: ${props => props.theme.pureWhite};
	border: 3px solid ${props => props.theme.grey5};
	box-sizing: border-box;
	border-radius: 4px;
	margin-right: 8px;
	flex-shrink: 0;
	cursor: pointer;

	${props => props.checked && `
	background: ${props.locked ? props.theme.grey3 : props.theme.pokiBlue};
	border: transparent;

	${StyledCheckIcon} {
		display: block;
	}
	`}

	${props => props.disabled && `
	border-color: ${props.theme.grey7};
	cursor: not-allowed;
	`}
`;

const Only = styled.div`
	opacity: ${isMobile ? '1' : '0'};
	color: ${props => props.theme.pokiBlue};
	padding: 0 8px;
	position: absolute;
	top: 6px;
	right: 0;
	font-size: 14px;
	font-weight: bold;
	line-height: 20px;
	height: 20px;

	&:hover {
		text-decoration: underline;
	}

	${props => !props.hasCount && `
	position: relative;
	top: -1px;
	right: -8px;
	margin-left: auto;
	`}
`;

const Count = styled.div`
	display: block;
	margin-left: auto;
	font-size: 14px;
	padding: 0 8px;
	position: relative;
	top: -1px;
	right: -8px;
	line-height: 20px;
`;

const CountLabel = styled.div`
	display: block;
	margin-left: auto;
	font-size: 14px;
	padding: 0 8px;
	position: relative;
	right: -8px;
	line-height: 20px;
	font-weight: normal;
`;

const ListItemContainer = styled.div`
	position: relative;
	display: flex;
	font-size: 16px;
	line-height: 16px;
	height: 32px;
	border-radius: 4px;
	padding: 8px 12px;
	cursor: pointer;

	&:hover {
		background: ${props => props.theme.grey7};

		${Only} {
			opacity: 1;
		}

		${Count} {
			visibility: hidden;
		}
	}

	${props => props.locked && `
	cursor: not-allowed;
	color: ${props.theme.grey3};
	`}
`;

const Head = styled.div`
	font-weight: bold;
	padding: 8px 16px;
	margin: 0 -4px 8px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${props => props.theme.grey7};
`;

const Title = styled.div`
	margin-right: 16px;
`;

const SearchInput = styled(TextInput)`
	margin: 0 8px 8px;
`;

const ListItem = props => {
	const { selectedValues, fakeNoneSelected, value, onClick, onOnlyClick } = props;

	const onlyRef = useRef();
	const hasCount = typeof value.count !== 'undefined';

	const handleClick = e => {
		if (value.locked) return;
		if (e.target === onlyRef.current) return;

		onClick(e);
	};

	const handleOnlyClick = e => {
		if (value.locked) return;

		onOnlyClick(e);
	};

	return (
		<ListItemContainer
			onClick={handleClick}
			locked={value.locked}
		>
			<CheckBox checked={!fakeNoneSelected && selectedValues && typeof selectedValues.find(check => check === value.value) !== 'undefined'} locked={value.locked}>
				<StyledCheckIcon />
			</CheckBox>
			{value.desc}
			{hasCount && <Count>{value.count}</Count>}
			<Only ref={onlyRef} hasCount={hasCount} onClick={handleOnlyClick}>{_`only`}</Only>
		</ListItemContainer>
	);
};

const TriggerableMultiSelectInput = props => {
	const { name, className, align = 'left', trigger, title, values, value: inValue, valueSetter, onChange, cantSelectNone = false, countLabel } = props;

	const containerRef = useRef();
	const inputRef = useRef();

	const [active, setActive] = useState(false);
	const [query, setQuery] = useState('');
	const [fakeNoneSelected, setFakeNoneSelected] = useState(false);

	const value = cantSelectNone && (!inValue || inValue.length === 0) ? [...values.map(v => v.value)] : inValue;

	const regexp = new RegExp(query.replace(/[^a-zA-Z\- ]+/g, ''), 'i');

	const parsedValues = useMemo(() => (
		values
			.filter(val => val.desc.match(regexp))
			.sort((a, b) => {
				if (typeof a.sortValue !== 'undefined') {
					return a.sortValue > b.sortValue ? -1 : 1;
				}

				if (typeof a.count !== 'undefined') {
					return a.count > b.count ? -1 : 1;
				}

				return a.desc < b.desc ? -1 : 1;
			})
	), [values, query]);

	const allChecked = useMemo(() => (
		parsedValues.filter(fval => value && typeof value.find(val => fval.value === val) !== 'undefined').length === parsedValues.length
	), [parsedValues, value]);

	useLayoutEffect(() => {
		if (inputRef.current?.form) {
			const inputEvent = new Event('input', { bubbles: true });
			inputRef.current.form.dispatchEvent(inputEvent);
		}
	}, [values]);

	const handleSetValue = (v, action, selected) => {
		let newValue = v;
		// If we can't select none, we just fake that nothing was selected (we actually have everything selected)
		if (cantSelectNone && (!v || v.length === 0)) {
			setFakeNoneSelected(true);
			newValue = [...values.map(_v => _v.value)];
		} else {
			setFakeNoneSelected(false);
		}

		if (newValue && value && newValue.join(',') === value.join(',')) return; // Ignore if nothing really changed

		valueSetter(newValue);

		if (onChange) {
			onChange(newValue, action, selected);
		}
	};

	useEffect(() => {
		setFakeNoneSelected(false);
	}, [active]);

	const createHandleListItemClick = item => () => {
		const newSelected = [];
		if (!fakeNoneSelected && value) {
			newSelected.push(...value);
		}

		let action = 'select';

		const current = newSelected.findIndex(check => item.value === check);
		if (current > -1) {
			action = 'deselect';
			newSelected.splice(current, 1);
		} else {
			newSelected.unshift(item.value);
		}

		handleSetValue(newSelected, action, item);
	};

	const createHandleListItemOnlyClick = item => () => {
		const locked = values.filter(v => v.locked);
		handleSetValue([item.value, ...locked.map(v => v.value)], 'only', item);
	};

	// Exit on clicking outside
	useEffect(() => {
		if (!active || !containerRef.current) return;

		const click$ = fromEvent(document.body, 'click').pipe(
			tap(e => {
				if (!containerRef.current.contains(e.target)) {
					setActive(false);
				}
			}),
		);

		const subscription = click$.subscribe();

		return () => {
			if (subscription) subscription.unsubscribe();
		};
	}, [active, containerRef.current]);

	const handleToggleSelectAll = () => {
		if (fakeNoneSelected) {
			setFakeNoneSelected(false);
			handleSetValue([...values.map(v => v.value)], 'toggleAll');
		} else if (allChecked) {
			// All checked, remove any visible values from the list
			handleSetValue(value.filter(val => !parsedValues.find(fval => fval.value === val)), 'toggleAll');
		} else if (parsedValues.length === values.length) {
			// Not all checked, filter doesn't do anything, ensure our values list is deterministic
			handleSetValue([...values.map(v => v.value)], 'toggleAll');
		} else {
			// Not all checked, filter active, combine current selection with what's filtered in
			handleSetValue(lodash.uniq([...(value || []), ...parsedValues.map(v => v.value)]), 'toggleAll');
		}
	};

	return (
		<StyledFieldset className={className} ref={containerRef}>
			<input type="hidden" name={name} ref={inputRef} value={value} />
			{trigger(() => setActive(a => !a), active)}
			{active && (
				<ListItemCard noPadding elevated align={align}>
					<Head>
						<CheckBox
							checked={!fakeNoneSelected && allChecked && parsedValues.length > 0}
							disabled={parsedValues.length === 0}
							onClick={handleToggleSelectAll}
						>
							<StyledCheckIcon />
						</CheckBox>
						<Title>{title}</Title>
						{countLabel && (
							<CountLabel>{countLabel}</CountLabel>
						)}
					</Head>
					{values.length >= 10 && (
						<SearchInput
							autoFocus
							icon={SearchIcon}
							valueSetter={setQuery}
							value={query}
							placeholder={_`searchPlaceholder`}
							small
						/>
					)}
					<Items>
						{parsedValues.map(val => (
							<ListItem
								key={`${name}-list-item-${val.value}`}
								value={val}
								selectedValues={value}
								fakeNoneSelected={fakeNoneSelected}
								onClick={createHandleListItemClick(val)}
								onOnlyClick={createHandleListItemOnlyClick(val)}
							/>
						))}
					</Items>
				</ListItemCard>
			)}
		</StyledFieldset>
	);
};

export default TriggerableMultiSelectInput;
