import { createAuthorizedApiEpic } from 'app/src/utils/api';
import getApiUrl from 'app/src/utils/getApiUrl';
import dataFormatter from 'app/src/utils/dataFormatter';

export const getMetrictestsByGameId = createAuthorizedApiEpic(
	'game/get_metrictests_for_game',
	(callApi, { gameId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/metrictests`),
		method: 'GET',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);

export const requestMetrictest = createAuthorizedApiEpic(
	'game/request_metrictests',
	(callApi, { gameId, data }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/metrictests`),
		method: 'POST',
		headers: {
			'Content-Type': 'application/vnd.api+json',
			Accept: 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				type: 'metrictests',
				...data,
			},
		})),
	}),
);

export const stopMetrictest = createAuthorizedApiEpic(
	'game/stop_metrictest',
	(callApi, { gameId, MetrictesttId }) => callApi({
		url: getApiUrl('devs', `games/${gameId}/metrictests/${MetrictesttId}/@stop`),
		method: 'POST',
		headers: { Accept: 'application/vnd.api+json' },
	}),
);
