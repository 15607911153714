import React from 'react';

import { useSelectTheme } from 'app/src/selectors/session';

const CatEmoji = props => {
	const { className, inactive } = props;
	const theme = useSelectTheme();

	return (
		<svg className={className} width="128" height="128" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21 26V27V28H20V27V26V25H21V24H22V25H23V26V27V28H22V27V26H21ZM27 28H23V29H20V28H19V33H17V34H16V35H17V34H19V35H18V36H17V37H18V36H19V37H20V38H21V40H22V41H23V40H24V41H36V39H37V41H44V40H46V41H47H48V40H47H46V39H45V36H44V34H43V31H42V30H40V29H35V30H32V29H31V28H30V27V26V25H29V24H28V25H27V26V27V28ZM27 28V29H30V28H29V27V26H28V27V28H27ZM38 38V39H37V38H38ZM38 38V37H41V38H38ZM25 39V40H24V39H25ZM25 39V38H27V39H25ZM21 34H22H23V33H24V32H23V33H21V32H20V33H21V34ZM25 37H26V36H25V35H24V36H23V37H24V36H25V37ZM28 33H29V32H28V33ZM26 33H25V32H26V33ZM26 33V34H28V33H26ZM31 37H32V36H31V35H30V36H31V37ZM32 35H33V34H32V33H30V34H32V35Z"
				fill={inactive ? theme.grey5 : theme.pokiBlue}
				shapeRendering="crispEdges"
			/>
		</svg>
	);
};

export default CatEmoji;
