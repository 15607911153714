/* eslint-disable */
import * as React from "react";

// WARNING: Manually added due to figma sync being dead.
// Likely as soon as figma sync is fixed there will be issues here.
const PokiNetlibIcon = props => (
	<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M10.9495 11.0336C9.17533 12.8586 9.22644 15.7668 11.0637 17.5292C12.673 19.073 15.1189 19.2255 16.8934 18.013L30.7768 31.331C29.6187 33.135 29.8575 35.558 31.4669 37.1019C33.3041 38.8643 36.2317 38.8135 38.0059 36.9885C39.7801 35.1635 39.729 32.2553 37.8918 30.4929C36.2824 28.949 33.8363 28.7965 32.0618 30.0092L18.1785 16.6913C19.3368 14.8873 19.098 12.4641 17.4886 10.9202C15.6513 9.15778 12.7237 9.20854 10.9495 11.0336Z" fill="#002B50"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M23.313 35.6501C22.456 39.8437 18.7235 43 14.249 43C9.14092 43 5 38.8866 5 33.8124V14.2122C5 13.5203 5.07699 12.8463 5.22293 12.1981C5.57872 10.5973 6.3702 9.07107 7.60065 7.80536C11.149 4.15531 17.0043 4.05379 20.6787 7.5786L25.6856 12.3816C26.5399 8.18446 30.2739 5.0246 34.751 5.0246C39.8591 5.0246 44 9.13802 44 14.2122V33.8124C44 38.8866 39.8591 43 34.751 43C33.3278 43 31.9796 42.6807 30.7752 42.1102C29.866 41.6905 29.0105 41.1156 28.2488 40.3849L23.313 35.6501ZM19.3937 8.9013L39.8135 28.4896C42.7531 31.3094 42.8349 35.9625 39.9962 38.8826C37.1575 41.8026 32.4733 41.8839 29.5337 39.064L9.11384 19.4757C6.17428 16.6559 6.09252 12.0028 8.93122 9.08271C11.7699 6.16267 16.4541 6.08145 19.3937 8.9013Z" fill="#002B50"/>
	</svg>
);

export default PokiNetlibIcon;
