/*
Implements react-router-dom Link but uses replace instead of push when pathname is the same
*/

import React from 'react';
import { Link as ReactRouterDomLink, useLocation } from 'react-router-dom';

const Link = props => {
	const { children, to } = props;

	if (to === null) return children; // No Link element if no to

	const location = useLocation();

	// Replace instead of push if the target isn't different from where we're at
	const replace = location.pathname === to;

	return <ReactRouterDomLink {...props} replace={replace} />;
};

export default Link;
