import { fromEvent, EMPTY, of, switchMap } from 'rxjs';
import { openModal } from 'app/src/actions/client';
import { selectPermissions } from 'app/src/selectors/user';

import { createAuthorizedApiEpic } from 'app/src/utils/api';
import getApiUrl from 'app/src/utils/getApiUrl';
import checkPermissions from 'app/src/utils/checkPermissions';

export const searchShortcutEpic = (action$, state$) => (
	fromEvent(window, 'keydown').pipe(
		switchMap(e => {
			const { keyCode } = e;
			const permissions = selectPermissions(state$.value);

			if (!checkPermissions(permissions, [['can_search_all']])) return EMPTY;

			if (keyCode === 191) { // "/"
				if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) return EMPTY;

				e.preventDefault();
				return of(openModal({ key: 'search' }));
			}

			return EMPTY;
		}),
	)
);

export const search = createAuthorizedApiEpic(
	'search',
	(callApi, query) => callApi({
		url: getApiUrl('devs', `search?q=${query}`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
		},
	}),
);
