{
	"engine": "GameMaker",
	"sub-title": "GML Engine and Editor",
	"official-partner": false,
	"website": "https://gamemaker.io/en",
	"documentation": "https://manual.gamemaker.io/monthly/en/#t=Content.htm",
	"community-links": [
		{
			"label": "Marketplace",
			"url": "https://marketplace.gamemaker.io/"
		}
	],
	"description": "GameMaker is an engine that works with GML (GameMaker Language) as its scripting language. Its extensive editor contains a lot of tools and features that help developers realise their 2D game concepts.",
	"games": [
		{
			"title": "Retro Bowl",
			"url": "https://poki.com/en/g/retro-bowl",
			"developer": "New Star Games"
		},
		{
			"title": "Iron Snout",
			"url": "https://poki.com/en/g/iron-snout",
			"developer": "SnoutUp Games"
		},
		{
			"title": "Driftwave",
			"url": "https://poki.com/en/g/driftwave",
			"developer": "Jamie Rowan x RowanFuture"
		}
	],
	"sections": {
		"tech-fit": {
			"file-size-of-empty-project": {
				"stars": 3,
				"description": "An empty project made with GameMaker will have a compressed export size of 450-550kb"
			},
			"support-for-mobile-web": {
				"stars": 3,
				"description": "With support for touch controls, responsive scaling and a small initial file size, games made with GameMaker are perfect to be played on mobile and desktop devices of any kind with any type of internet speed."
			}
		},
		"capabilities": {
			"2D": {
				"stars": 3,
				"description": "If you’re working on a 2D project, GameMaker is a great engine to consider. Being primarily focused on 2D development, this engine offers all tools you need. It also has extensive animation capabilities such as spine imports, SWF imports, animation curves and timeline animations. It also uses its own custom physics system based on Box2D."
			},
			"3D": {
				"stars": 1,
				"description": "GameMaker is less suited for making 3D games. Some basic features are available, such as shaders, matrices and vertex buffers. These can help create interesting visual styles for your game, but require a lot of know-how to make a fully fledged 3D game with."
			},
			"multiplayer": {
				"stars": 2,
				"description": "GameMaker has native options available to set up multiplayer. Besides this, there are community-made extensions available that can help you integrate the back-end service of your choice into your project."
			}
		},
		"team-fit": {
			"collaboration": {
				"stars": 3,
				"description": "The GameMaker IDE contains shortcuts and quick actions for Git, allowing you to quickly and easily safe and share your progress with your team. The visual part of the editor makes it possible to do the coding and graphical work in the same place."
			},
			"licensing": {
				"stars": 2,
				"description": "Getting started with GameMaker is free. However, to be able to commercialise your games and export to all possible platforms, you will need to get a Professional license, which is a one-time purchase, or buy a subscription to the Enterprise version."
			}
		}
	}
}