import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { lightColors } from 'shared/vars';

import MarketingLinks from 'site/src/components/MarketingLinks';
import { BackgroundImage } from 'site/src/components/styled';

const _large = '@media (min-width: 1367px)';

const Clip = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: -200px;
	background-color: #fff;
	transform: skewY(-.5deg);
	transform-origin: bottom left;
	max-height: 100%;
`;

const Footer = styled.footer`
	position: relative;
	display: block;
	width: 100%;
	font-size: 16px;
	line-height: 26px;
	color: ${lightColors.grey3};
`;

const FooterContainer = styled.div`
	flex-direction: column-reverse;
	padding-bottom: 56px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 30px 14px;
	background: #fff;

	${_large} {
		flex-direction: row;
		padding-bottom: 12px;
	}
`;

const FooterItem = styled.div`
	position: relative;

	&:first-child {
		float: left;
		z-index: 1;
	}

	&:not(:last-child) {
		flex: 1;

		${_large} {
			flex: none;
		}
	}

	&:nth-child(1) {
		text-align: left;
		padding-left: 5px;
		float: left;

		${_large} {
			padding-left: 15px;
		}
	}

	${_large} {
		padding-left: 15px;
		padding-right: 15px;
	}

	a {
		color: ${lightColors.grey3};
		border-bottom: solid 1px ${lightColors.grey3};
		font-size: 16px;
		line-height: 26px;


		&:hover {
			color: ${lightColors.grey1};
			border-bottom: solid 1px ${lightColors.grey1};
		}
	}

	${props => props.social && `
	width: 100%;
	margin-bottom: 14px;
	margin-top: 7.5px;
	`}
`;

const FooterLeft = styled.div`
	flex-wrap: nowrap;
	align-items: center;
	text-align: center;
	width: 342px;
	display: block;
	margin-top: 7.5px;

	${_large} {
		display: flex;
		text-align: left;
		flex-direction: row;
		width: auto;
		margin-top: 0;
	}
`;

const FooterRight = styled.div`
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	flex-direction: row-reverse;
	flex-wrap: wrap-reverse;
	text-align: center;
	width: 100%;

	${_large} {
		flex-direction: row;
		flex-wrap: nowrap;
		text-align: left;
		width: auto;
	}

	${FooterItem}:nth-child(2) {
		text-align: right;
		padding-right: 5px;
	}
`;

const Social = styled.div`
	display: flex;
	justify-content: center;
	transform: translateY(2px);
`;

const SocialIcon = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	line-height: 39px;
	border-bottom: none;
	margin: 0 3px;
	border-radius: 50%;
	background: ${lightColors.denimBlue};

	&:hover {
		background: ${lightColors.pokiBlue};
	}
`;

const MarketingFooter = ({ small = false, tiny = false }) => (
	<>
		{!small && !tiny && <MarketingLinks />}
		{small && <BackgroundImage backgroundUrl="/images/footer_coin.svg" />}
		<Footer>
			{tiny && <Clip />}
			<FooterContainer>
				<FooterLeft>
					<FooterItem>
						©{moment().year()}&nbsp;
						<a href="http://about.poki.com" target="_blank" rel="noreferrer noopener"><strong>Poki B.V.</strong></a>
					</FooterItem>
					<FooterItem>
						<a href="https://goo.gl/maps/EKz1MmVJnDRY1Vb97" target="_blank" rel="noreferrer noopener">Spui 10</a>
						, 1012 WZ • Amsterdam, The Netherlands
					</FooterItem>
				</FooterLeft>
				<FooterRight>
					<FooterItem>
						<a href="https://poki.com/en/c/privacy-statement" target="_blank" rel="noreferrer noopener">Privacy Policy</a>
					</FooterItem>
					<FooterItem>
						<a href="http://poki.com/en/c/terms-of-use" target="_blank" rel="noreferrer noopener">Terms Of Use</a>
					</FooterItem>
					<FooterItem social={1}>
						<Social>
							<SocialIcon href="https://www.linkedin.com/company/poki" target="_blank" rel="noreferrer noopener">
								<img src="/images/icon-linkedin.svg" width="16" height="15" alt="Icon Linkedin" />
							</SocialIcon>
							<SocialIcon href="https://blog.poki.com" target="_blank" rel="noreferrer noopener">
								<img src="/images/icon-medium.svg" width="18" height="15" alt="Icon Medium" />
							</SocialIcon>
						</Social>
					</FooterItem>
				</FooterRight>
			</FooterContainer>
		</Footer>
	</>
);

export default MarketingFooter;
