import dataFormatter from 'app/src/utils/dataFormatter';
import { getPlaytestRecordingsByGameId } from 'app/src/epics/playtests';
import { setVideoJS, setVideoSeekId } from 'app/src/actions/playtest';

const initialState = {
	recordings: [],
	videoJS: null,
	videoSeekId: 0,
};

export default function playtestsReducer(state = initialState, action) {
	if (action.type === getPlaytestRecordingsByGameId.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			recordings: {
				...state.recordings,

				[action.payload.options.gameId]: {
					data,
					links,
					meta,
				},
			},
		};
	}

	if (action.type === setVideoJS.type) {
		return {
			...state,
			videoJS: action.payload,
		};
	}

	if (action.type === setVideoSeekId.type) {
		return {
			...state,
			videoSeekId: state.videoSeekId + 1,
		};
	}

	return state;
}
