/**
 * checkPermissions
 *
 * Needs at least one of the permissions of every given permission set
 *
 * Examples:
 * checkPermissions(permissions, [['permission_a', 'permission_b']]) 					-> true if permissions includes 'permission_a' OR 'permission_b'
 * checkPermissions(permissions, [['permission_a', 'permission_b'], ['permission_c']])	-> true if permissions includes ('permission_a' OR 'permission_b') AND 'permission_c'
 */

const checkPermissions = (permissions, needsPermissions) => (
	!needsPermissions || needsPermissions.every(needsOneOfPermissions => !needsOneOfPermissions || needsOneOfPermissions.some(permission => permissions.includes(permission)))
);

export default checkPermissions;
