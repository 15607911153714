import dataFormatter from 'app/src/utils/dataFormatter';
import { getAllPendingReviews, getAllReportsForToday, getAllReportsForYesterday, getAllReviewsForToday, getCompletedReviews, getPendingReviews, getReviewById, getReviewsForGame } from 'app/src/epics/review';

const initialState = {
	reviewsByGameId: {},
	pendingReviews: {},
	completedReviews: {},
	reviewsById: {},
	allPendingReviews: {},
	allReviewsToday: {},
	allReportsToday: {},
	allReportsYesterday: {},
};

export default function reviewReducer(state = initialState, action) {
	if (action.type === getReviewsForGame.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			reviewsByGameId: {
				...state.reviewsByGameId,

				[action.payload.options.gameId]: {
					data,
					links,
					meta,
				},
			},
		};
	}

	if (action.type === getAllPendingReviews.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;

		const data = dataFormatter.deserialize(response);

		return {
			...state,
			allPendingReviews: {
				data,
				links,
				meta,
			},
		};
	}

	if (action.type === getPendingReviews.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;

		const data = dataFormatter.deserialize(response);

		return {
			...state,
			pendingReviews: {
				data,
				links,
				meta,
			},
		};
	}

	if (action.type === getCompletedReviews.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;

		const data = dataFormatter.deserialize(response);

		return {
			...state,
			completedReviews: {
				data,
				links,
				meta,
			},
		};
	}

	if (action.type === getReviewById.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			reviewsById: {
				...state.reviewsById,

				[action.payload.options.reviewId]: {
					data,
					links,
					meta,
				},
			},
		};
	}

	if (action.type === getAllReviewsForToday.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;

		const data = dataFormatter.deserialize(response);

		return {
			...state,
			allReviewsToday: {
				data,
				links,
				meta,
			},
		};
	}

	if (action.type === getAllReportsForToday.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;

		const data = dataFormatter.deserialize(response);

		return {
			...state,
			allReportsToday: {
				data,
				links,
				meta,
			},
		};
	}

	if (action.type === getAllReportsForYesterday.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;

		const data = dataFormatter.deserialize(response);

		return {
			...state,
			allReportsYesterday: {
				data,
				links,
				meta,
			},
		};
	}

	return state;
}
