import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import 'tippy.js/dist/tippy.css';

// Tooltip component using tippyjs library
// Children needs to be wrapped in a span in order to work properly

const TooltipTippy = styled(Tippy)`
    padding: 8px;
    border-radius: 8px;
    background: ${props => props.theme.pureWhite};
    box-shadow: ${props => props.theme.boxShadowSmall};
    color: ${props => props.theme.grey1};

	.tippy-arrow {
		color: ${props => props.theme.pureWhite};
	}
`;

const TooltipTippyContent = styled.span`
	padding: 0;
	margin: 0;
`;

const TooltipIcon = styled.div`
	width: 24px;
	height: 24px;

	> svg [fill] {
		transition: fill 0.1s ease-in-out;
		fill: ${props => props.theme.grey5};
	}

	${props => (props.$warning && `
	> svg [fill] {
		fill: ${props.theme.yellow3};
	}
	`)}

	${props => (!props.$disabled ? `
	&:hover,
	&:focus {
		> svg [fill] {
			fill: ${props.theme.pokiBlue};
		}
	}
	` : `
	> svg [fill] {
		fill: ${props.theme.grey7};
	}
	`)}
`;

const Tooltip = props => {
	const {
		className,
		content,
		children,
		icon,
		maxWidth,
		disabled = false,
		warning = false,
		placement = 'right',
		followCursor: followCursorProp = false,
		arrow = false,
		visible,
		onHide,
	} = props;

	return (
		<TooltipTippy
			appendTo={document.body}
			plugins={[followCursor]}
			followCursor={followCursorProp}
			arrow={arrow}
			delay={[0, 0]}
			disabled={disabled}
			placement={placement}
			content={content}
			maxWidth={maxWidth || '100%'}
			interactive
			visible={visible}
			onHide={onHide}
		>
			<TooltipTippyContent className={className}>
				{icon && (
					<TooltipIcon $disabled={disabled} $warning={warning}>
						{icon}
					</TooltipIcon>
				)}
				{children}
			</TooltipTippyContent>
		</TooltipTippy>
	);
};

export default Tooltip;
