import 'shared/wdyr';

import 'array-flat-polyfill';

import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
/*
HistoryRouter is unstable because it uses an internal history package that might differ from the one we use
Should not give any errors, but good to keep an eye on react-router-dom and upgrade when possible
*/
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import history from 'site/history';
import { initializeStore } from 'app/src/store';
import Root from 'site/src/components/Root';
import { prep as prepEmojiHover } from 'shared/utils/emojiHover';
import { redirect } from 'site/redirect';

// Hot Reloading
if (module.hot) module.hot.accept();

redirect();
localStorage.removeItem('state/persistedSession');

const container = document.getElementById('site-root');
const root = createRoot(container);

const store = initializeStore({});

// Render
root.render(
	<Provider store={store}>
		<HistoryRouter history={history}>
			<Root />
		</HistoryRouter>
	</Provider>,
);

prepEmojiHover();
