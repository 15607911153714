if (typeof Array.prototype.flat === 'undefined') {
	/* eslint-disable */
	// https://stackoverflow.com/questions/10865025/merge-flatten-an-array-of-arrays
	Object.defineProperty(Array.prototype, 'flat', {
		value: function(depth = 1) {
		return this.reduce(function (flat, toFlatten) {
			return flat.concat((Array.isArray(toFlatten) && (depth>1)) ? toFlatten.flat(depth-1) : toFlatten);
		}, []);
		}
	});
	/* eslint-enable */
}

const flattenEpics = epics => Object.values(epics).map(epic => {
	if (typeof epic !== 'function') {
		return Object.values(epic);
	}
	return epic;
}).flat();

export default flattenEpics;
