import { signoutWithNewEmail } from 'app/src/actions/session';
import { refreshAuth } from 'app/src/epics/session';

const initialState = {
	isLoggingOutWithNewEmail: false,
	isRefreshing: false,
};

const fetchType = refreshAuth.fetch().type;

export default function sessionReducer(state = initialState, action) {
	if (action.type === signoutWithNewEmail.type) {
		return {
			...state,
			isLoggingOutWithNewEmail: true,
		};
	}

	if (action.type === fetchType) {
		return {
			...state,
			isRefreshing: true,
		};
	}

	if (action.type === refreshAuth.success.type || action.type === refreshAuth.error.type) {
		return {
			...state,
			isRefreshing: false,
		};
	}

	return state;
}
