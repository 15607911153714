import { InvalidParameterError } from 'app/src/errors';

const modals = {};

export function registerModal(name, component) {
	modals[name] = component;
}

export function getModal(name) {
	if (name === null) return;
	if (!modals[name]) throw new InvalidParameterError(`Modal ${name} not found! Are you sure you registered it? And did you import it in Routes.js?`);
	return modals[name];
}
