import React, { useRef } from 'react';
import styled from 'styled-components';

import { motionSpeed02, motionOut } from 'shared/vars';
import _ from 'shared/copy';

const StyledTextArea = styled.textarea`
	display: block;
	padding: 12px 16px;
	line-height: 24px;
	width: 100%;
	height: ${props => props.$height};

	${props => props.$small && `
	height: 83px;
	`}

	background: ${props => props.theme.grey7};
	color: ${props => props.theme.denimBlue};
	border: 1px solid ${props => props.theme.grey5};
	border-radius: 8px;

	font-size: 16px;
	resize: ${props => !props.$resize && 'none'};
	transition: background ${motionSpeed02} ${motionOut}, color ${motionSpeed02} ${motionOut};

	::placeholder {
		color: ${props => props.theme.grey3};
	}

	&:focus {
		outline: none;
		border: 3px solid ${props => props.theme.pokiBlue};
		padding: 10px 14px;
		background: ${props => props.theme.grey9};
	}

	${props => (props.disabled ? `
	color: ${props.theme.grey3};
	cursor: not-allowed;
	border: none;
	padding: 13px 17px;
	` : `
	&:hover {
		background: ${props.theme.grey9};
	}
	`)}

	${props => props.error && `
	border-color: ${props.theme.rose1};
	background: ${props.theme.isDarkMode ? props.theme.static.rose1 : props.theme.static.rose9};
	`}
`;

const InputContainer = styled.div`
	position: relative;
	margin-bottom: 8px;

	> svg {
		position: absolute;
		top: 17px;
		left: 17px;
		pointer-events: none;
	}

	> svg + ${StyledTextArea} {
		padding-left: 44px;

		&:focus {
			padding-left: 42px;
		}
	}

	> svg path {
		fill: ${props => props.theme.grey3};
	}

	&:focus-within {
		> svg path {
			fill: ${props => props.theme.pokiBlue};
		}
	}
`;

const StyledFieldset = styled.fieldset`
	position: relative;
	min-width: 0;
	max-width: 500px;
	margin-bottom: 16px;
`;

const StyledLabel = styled.label`
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: ${props => props.theme.grey3};

	${StyledTextArea} {
		margin-top: 4px;
	}

	${props => props.error && `
	color: ${props.theme.rose1};
	`}
`;

const Description = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;
	min-height: 18px;

	${props => props.shortDesc && `
		min-height: 18px;
		text-align: right;
		margin-top: 4px;
	`}
`;

const Error = styled.div`
	color: ${props => props.theme.rose1};
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;

	& + & {
		margin-top: 0;
	}
`;

const Required = styled.div`
	color: ${props => props.theme.grey3};
	font-size: 14px;
	line-height: 18px;
	position: absolute;
	top: 4px;
	right: 0;
`;

const TextAreaInput = props => {
	const { autoFocus, className, name, errors = [], description, disabled, icon: Icon, placeholder, label, value, valueSetter, onChange, maxLength, shortDesc, required, small, height = '166px', resize = true } = props;

	const handleChange = evt => {
		if (valueSetter) valueSetter(evt.target.value);
		if (onChange) onChange(evt);
	};

	const ref = useRef();

	const input = (
		<InputContainer>
			{Icon && <Icon />}
			<StyledTextArea
				ref={ref}
				id={name}
				autoFocus={!!autoFocus}
				name={name}
				disabled={disabled}
				placeholder={placeholder}
				onChange={handleChange}
				maxLength={maxLength}
				error={errors.length > 0}
				value={value}
				$small={small}
				$height={height}
				$resize={resize}
			/>
		</InputContainer>
	);

	return (
		<StyledFieldset className={className}>
			{required && <Required>{_`required`}</Required>}
			{label ? (
				<StyledLabel htmlFor={name}>{label}{input}</StyledLabel>
			) : input}
			{errors.length > 0 ? errors.map(err => <Error key={err}>{err}</Error>) : (
				description && (
					<Description shortDesc={shortDesc}>{description}</Description>
				)
			)}
		</StyledFieldset>
	);
};

export default TextAreaInput;
