import { of, map, switchMap } from 'rxjs';
import { createApiEpic } from '@poki/rx-api';
import moment from 'moment';

import { selectAccessToken, selectRefreshToken, selectTokenExpires, selectIsRefreshing } from 'app/src/selectors/session';
import { refreshAuth } from 'app/src/epics/session';
import { runAfterRefresh } from 'app/src/actions/session';

export const formatDateAsString = date => {
	if (moment.isMoment(date)) {
		date = date.toDate();
	}
	return `${date.getFullYear()}-${`${(date.getMonth() + 1)}`.padStart(2, '0')}-${`${date.getDate()}`.padStart(2, '0')}`;
};

export const createAuthorizedApiEpic = (id, handler, getCBStream, options) => {
	const authorizedHandler = (callApi, _options = {}, state = {}) => {
		// Helper method to create callApi based on access token
		const createCallApi = accessToken => (
			// Execute original handler, and pipe the result
			handler(callApi, _options, state).pipe(
				// Adjust resulting action if necessary
				map(action => {
					if (action.type === callApi.type) {
						// Inject authorization header
						return {
							...action,
							payload: {
								...(action.payload || {}),
								headers: {
									...(action.payload.headers || {}),
									Authorization: `Bearer ${accessToken}`,
								},
							},
						};
					}

					// Pass through any other actions directly
					return action;
				}),
			)
		);

		const expires = selectTokenExpires(state);
		if (expires < Date.now()) {
			const refreshToken = selectRefreshToken(state);
			const isRefreshing = selectIsRefreshing(state);

			if (isRefreshing) {
				return of(runAfterRefresh({ handler: state$ => createCallApi(selectAccessToken(state$.value)) }));
			}

			// Refresh before handling original api request
			return of(
				refreshAuth.fetch(
					{ refreshToken },
					({ success$ }) => (
						success$.pipe(
							switchMap(({ payload: { result: { response } } }) => createCallApi(response.access_token)),
						)
					),
				),
			);
		}

		// No refreshing necessary
		const accessToken = selectAccessToken(state);
		return createCallApi(accessToken);
	};

	return createApiEpic(id, authorizedHandler, getCBStream, options);
};

export const sortPageFilterQuery = ({ sortField, sortDirection, page, perPage, filters = {} }) => {
	let result = '';

	if (sortField) {
		result += `sort=${sortDirection < 0 ? '-' : ''}${sortField}`;
	}

	if (page) {
		if (result.length > 0) result += '&';

		result += `page[number]=${page}`;
	}

	if (perPage) {
		if (result.length > 0) result += '&';

		result += `page[size]=${perPage}`;
	}

	Object.keys(filters).forEach(field => {
		if (result.length > 0) result += '&';

		result += `filter[${field}]=${filters[field]}`;
	});

	return result;
};
