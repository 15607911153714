import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

const getButtonHoverColor = props => (props.positive ? props.theme.green3 : props.negative ? props.theme.rose5 : props.theme.pokiBlue);

const getStyle = props => `
	display: block;
	position: relative;
	border-radius: 100%;
	transition: 0.1s ease-in-out;
	flex-shrink: 0;
	width: 36px;
	height: 36px;
	background: ${props.theme.grey7};
	display: flex;
	align-items: center;
	justify-content: center;

	&:focus {
		outline: none;
	}

	svg [fill] {
		transition: 0.1s ease-in-out;
		fill: ${props.theme.grey3};
	}

	${!props.disabled && `
	cursor: pointer;

	&:hover,
	&:focus {
		background: ${getButtonHoverColor(props)};

		svg [fill] {
			fill: ${props.theme.pureWhite};
		}
	}
	`}

	${props.disabled && `
	background: ${props.theme.grey9};
	cursor: not-allowed;

	svg [fill] {
		fill: ${props.theme.grey5};
	}
	`}
`;

export const StyledButton = styled.button(props => getStyle(props));
export const StyledAnchor = styled.a(props => getStyle(props));

const IconButton = props => {
	const { className, icon: Icon, submit, to, secondary, negative, onClick, disabled, openInNewTab, title } = props;

	const navigate = useNavigate();

	let clickHandler;
	let Element;
	if (to && !disabled) {
		Element = StyledAnchor;
		clickHandler = e => {
			if (onClick) onClick(e);

			if (!to.match(/^http/) && !openInNewTab) {
				e.preventDefault();
				navigate(to);
			} // Otherwise, it's just a link
		};
	} else {
		Element = StyledButton;
		if (onClick) {
			clickHandler = e => {
				e.preventDefault();
				onClick(e);
			};
		}
	}

	const button = (
		<Element
			className={className}
			onClick={clickHandler}
			secondary={secondary}
			disabled={disabled}
			negative={negative}
			rel={openInNewTab ? 'noopen noreferrer' : ''}
			target={openInNewTab ? '_blank' : undefined}
			href={to}
			type={submit ? 'submit' : 'button'}
			title={!disabled ? title : undefined}
		>
			<Icon />
		</Element>
	);

	return button;
};

export default IconButton;
