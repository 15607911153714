import React from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import StarFilledIcon from 'shared/designTokens/icons/ui/small/StarFilledIcon';

const StarUnfilledIcon = styled(StarFilledIcon)`
	[fill] {
		fill: transparent;
	}
	stroke: ${props => props.theme.grey5};
	stroke-width: 2;
`;

const Container = styled.div`
	height: 24px;
`;

const Stars = ({ rating, total }) => {
	if (rating > total) {
		rating = total;
	}

	return (
		<Container>
			{Array.from({ length: rating }, (_, i) => (
				<StarFilledIcon key={i} />
			))}
			{Array.from({ length: total - rating }, (_, i) => (
				<StarUnfilledIcon key={i} />
			))}
		</Container>
	);
};
Stars.defaultProps = {
	rating: 2,
	total: 3,
};
Stars.propTypes = {
	rating: PT.number,
	total: PT.number,
};

export default Stars;
