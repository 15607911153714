import { useEffect } from 'react';
import { Subject, takeUntil } from 'rxjs';

import { epicRoot$ } from 'app/src/store';

/**
 * useEpic
 *
 * Defines an epic that is active for the lifetime of this component
 * Warning: Make sure that this is really what you want to do, usually an actual (global) epic is what you need :)
 */

export default function useEpic(epic$, params) {
	useEffect(() => {
		const unsubscriber$ = new Subject();

		epicRoot$.next(
			(...args) => epic$(...args).pipe(
				takeUntil(unsubscriber$),
			),
		);

		return () => {
			unsubscriber$.next();
		};
	}, params);

	return epic$;
}
