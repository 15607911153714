import { retrieveUserDetails } from 'app/src/epics/user';
import { signout, signoutWithNewEmail } from 'app/src/actions/session';
import dataFormatter from 'app/src/utils/dataFormatter';

const initialState = {
	user: null,
	impersonator: {},
	permissions: [],
	teamTemporarilyLockedOut: false,
};

export default function userReducer(state = initialState, action) {
	if (action.type === signout.type || action.type === signoutWithNewEmail.type) {
		return { ...initialState };
	}

	if (action.type === retrieveUserDetails.success.type) {
		const { response } = action.payload.result;
		const { meta } = response;

		const user = dataFormatter.deserialize(response);

		return {
			...state,
			user,
			permissions: action.payload.result.response.meta.permissions,
			impersonator: action.payload.result.response.meta.impersonator,
			teamTemporarilyLockedOut: !!meta.team_temporarily_locked_out,
		};
	}
	return state;
}
