import React from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { useSelectIsAuthenticated, useSelectIsDarkMode } from 'app/src/selectors/session';
import { lightColors, darkColors } from 'shared/vars';

const ThemeProvider = ({ children, forceLightMode = false }) => {
	let isDarkMode = useSelectIsDarkMode();
	const isAuthenticated = useSelectIsAuthenticated();

	if (!children) return null;

	const colors = (isDarkMode && isAuthenticated) && !forceLightMode ? darkColors : lightColors;

	if (forceLightMode) {
		isDarkMode = false;
	}

	return (
		<StyledComponentsThemeProvider
			theme={{
				...colors,

				static: lightColors,
				isDarkMode,
			}}
		>
			{children}
		</StyledComponentsThemeProvider>
	);
};

export default ThemeProvider;
