const createError = name => (
	class CustomError extends Error {
		constructor(message) {
			super(message);
			this.name = name;
		}
	}
);

export const InvalidParameterError = createError('InvalidParameterError');
export const InvalidPropError = createError('InvalidPropError');
export const LocalizationError = createError('LocalizationError');
export const UnauthorizedError = createError('UnauthorizedError');
