import { ofType } from 'redux-observable';
import { of, tap, throttleTime, switchMap, filter } from 'rxjs';
import * as Sentry from '@sentry/browser';
import { clearAllStatuses } from '@poki/rx-api';

import { uncaughtServerError, openToast, locationChanged } from 'app/src/actions/client';
import { ToastTypes } from 'app/src/components/ui/Toast';
import _ from 'shared/copy';
import { clearData } from 'app/src/actions/data';

export function clearAllStatusesEpic(action$) {
	return action$.pipe(
		ofType(locationChanged.type),
		filter(({ payload }) => payload?.action === 'PUSH'),
		switchMap(() => of(clearAllStatuses(), clearData())),
	);
}

export function handleUncaughtServerErrorEpic(action$) {
	return action$.pipe(
		ofType(uncaughtServerError.type),
		tap(({ action }) => console.error('❌', action)),
		throttleTime(1000),
		switchMap(({ action }) => {
			const { payload: { result: { status } } } = action;

			// Log to Sentry
			Sentry.withScope(scope => {
				if (action.type.includes('/session/')) {
					// Only set some data if it's a session API call to avoid tracking passwords or tokens
					scope.setExtras({ type: action.type, payload: { result: { status } } });
				} else {
					// Send over all data
					scope.setExtras(action);
				}

				Sentry.captureException(new Error(`rx-api error (${action.type})`));
			});

			// Show a toast
			if (status === 0) {
				return of(openToast({ body: _`serverUnreachable`, toastType: ToastTypes.WARNING }));
			}

			return of(openToast({ body: _`unexpectedServerError${{ status }}`, toastType: ToastTypes.WARNING }));
		}),
	);
}
