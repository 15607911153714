import React, { useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';

import { lightColors } from 'shared/vars';

import { useSelectModal } from 'app/src/selectors/client';
import { getModal } from 'app/src/modals';

import ThemeProvider from 'app/src/utils/ThemeProvider';
import Routes from 'site/src/components/Routes';

const RootCSS = createGlobalStyle`
	* {
		box-sizing: border-box;
	}

	html, body {
		width: 100%;
		height: 100%;
		margin: 0;
		min-width: 320px;
		line-height: 24px;
		background: ${lightColors.pureWhite};
		-webkit-font-smoothing: antialiased;
	}

	html, body, input, button, textarea {
		font-family: 'Proxima Nova', Arial;
		color: ${lightColors.denimBlue};
	}

	h2 {
		margin: 0 0 24px;
		font-size: 24px;
		line-height: 24px;
	}

	button {
		border: 0;
	}

	#site-root {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	fieldset {
		border: none;
		margin: 0;
		padding: 0;
	}

	a {
		color: ${lightColors.pokiBlue};
		text-decoration: none;
		cursor: pointer;

		&:hover {
			color: ${lightColors.pokiHover};
		}

		&:active {
			color: ${lightColors.denimBlue};
		}
	}

	/* Tooltips */
	[title] {
		position: relative;

		::before {
			content: attr(title);
			font-size: 12px;
			font-weight: bold;
			background: ${lightColors.denimBlue};
			color: ${lightColors.pureWhite};
			padding: 2px 16px 0;
			border-radius: 6px;
			position: absolute;
			left: 50%;
			top: 0;
			transform: translate(-50%, calc(-100% - 8px));
			white-space: nowrap;
			display: none;
			line-height: 24px;
		}

		::after {
			content: '';
			border: 4px solid transparent;
			border-top-color: ${lightColors.denimBlue};
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, calc(-50% - 4px));
			display: none;
		}

		&:hover,
		&:focus {
			::before, ::after {
				display: block;
			}
		}
	}

	img.emoji {
		height: 1em;
		width: 1em;
		margin: 0 .05em 0 .1em;
		vertical-align: -0.1em;
	}
`;

const Root = () => {
	const modal = useSelectModal();
	const Modal = modal && getModal(modal.key);

	useEffect(() => {
		// prevent drag firing 0,0 events
		document.addEventListener('dragover', event => {
			event.preventDefault();
		}, false);
	}, []);

	return (
		<ThemeProvider forceLightMode>
			<RootCSS />
			<Routes />
			{Modal && <Modal data={modal.data} />}
		</ThemeProvider>
	);
};

export default Root;
