import { useSelector } from 'react-redux';

export const selectViewport = state => state.client.viewport;
export const selectModal = state => state.client.modal;
export const selectNavigationIsActive = state => state.client.navigationActive;
export const selectIsDateRangeInputOpen = state => state.client.isDateRangeInputOpen;
export const selectIsDragging = state => state.client.isDragging;
export const selectToasts = state => state.client.toasts;
export const selectPreventPageLeave = state => state.client.preventPageLeave;

export const useSelectViewport = options => useSelector(state => selectViewport(state), options);
export const useSelectModal = options => useSelector(state => selectModal(state), options);
export const useSelectNavigationIsActive = options => useSelector(state => selectNavigationIsActive(state), options);
export const useSelectIsDateRangeInputOpen = () => useSelector(state => selectIsDateRangeInputOpen(state));
export const useSelectIsDragging = () => useSelector(state => selectIsDragging(state));
export const useSelectToasts = () => useSelector(state => selectToasts(state));
export const useSelectPreventPageLeave = () => useSelector(state => selectPreventPageLeave(state));
