import { setAccessToken, setTokenTTL, setRefreshToken, signout, toggleDarkMode, persistHideMessageBox, signoutWithNewEmail, setCachedURLTeamCode, setVisitedTeam, setPreparedInvoice, setUnpreparedInvoice, setPreviousRefreshToken, setErrorDetailsPagination, setInitialErrorsDetailsPagination, setRedirectPathname, clearRedirectPathname, setGameChecklistStatus, setHasPlaytestNotifications } from 'app/src/actions/session';

export const initialState = {
	previousRefreshToken: null,
	accessToken: null,
	expires: null,
	refreshToken: null,
	colorScheme: (window?.matchMedia('(prefers-color-scheme: dark)').matches) ? 'dark' : 'light',
	persistedHiddenMessageboxes: {},
	cachedURLTeamCode: null,
	visitedTeams: [],
	preparedInvoices: [],
	errorDetailsPagination: {},
	hasPreviouslySignedIn: false,
	redirectPathname: null,
	gameChecklistStatus: {},
	hasPlaytestNotifications: false,
};

const signoutOverride = {
	accessToken: null,
	expires: null,
	refreshToken: null,
	previousRefreshToken: null,
	cachedURLTeamCode: null,
	visitedTeams: [],
};

export default function sessionReducer(state = initialState, action) {
	if (action.type === setAccessToken.type) {
		return {
			...state,
			accessToken: action.accessToken,
			hasPreviouslySignedIn: true,
		};
	}

	if (action.type === setTokenTTL.type) {
		return {
			...state,
			expires: Date.now() + (action.ttl * 1000),
		};
	}

	if (action.type === setRefreshToken.type) {
		return {
			...state,
			refreshToken: action.refreshToken,
		};
	}

	if (action.type === setPreviousRefreshToken.type) {
		return {
			...state,
			previousRefreshToken: action.previousRefreshToken,
		};
	}

	if (action.type === signout.type || action.type === signoutWithNewEmail.type) {
		return {
			...state,
			...signoutOverride,
		};
	}

	if (action.type === toggleDarkMode.type) {
		return {
			...state,
			colorScheme: action.toggle ? 'dark' : 'light',
		};
	}

	if (action.type === persistHideMessageBox.type) {
		return {
			...state,
			persistedHiddenMessageboxes: {
				...state.persistedHiddenMessageboxes,
				[action.id]: true,
			},
		};
	}

	if (action.type === setCachedURLTeamCode.type) {
		return {
			...state,
			cachedURLTeamCode: action.code,
		};
	}

	if (action.type === setVisitedTeam.type) {
		const { team } = action;
		const visitedTeams = state.visitedTeams?.filter(({ code }) => code !== team.code) || [];

		visitedTeams.length = Math.min(visitedTeams.length, 4);
		visitedTeams.unshift({ code: team.code, name: team.name, primaryCountry: team.primary_country });

		return {
			...state,
			visitedTeams,
		};
	}

	if (action.type === setPreparedInvoice.type) {
		const { id } = action;

		return {
			...state,
			preparedInvoices: [
				...state.preparedInvoices.filter(i => i !== id),
				id,
			],
		};
	}

	if (action.type === setUnpreparedInvoice.type) {
		const { id } = action;

		return {
			...state,
			preparedInvoices: [
				...state.preparedInvoices.filter(i => i !== id),
			],
		};
	}

	if (action.type === setInitialErrorsDetailsPagination.type) {
		const { errors } = action;

		return {
			...state,
			errorDetailsPagination: errors,
		};
	}

	if (action.type === setErrorDetailsPagination.type) {
		const { id, page } = action;

		return {
			...state,
			errorDetailsPagination: {
				...state.errorDetailsPagination,
				[id]: page,
			},
		};
	}

	if (action.type === setRedirectPathname.type) {
		return {
			...state,
			redirectPathname: action.pathname,
		};
	}

	if (action.type === clearRedirectPathname.type) {
		return {
			...state,
			redirectPathname: null,
		};
	}

	if (action.type === setGameChecklistStatus.type) {
		const { gameId, level, step, status } = action;

		if (status === 'pending' && state.gameChecklistStatus[gameId]?.[level]?.[step - 1]?.status === 'completed') {
			// Don't allow setting a step to pending if it's already completed.
			return state;
		}

		const newGameCheclistStatus = {
			...state.gameChecklistStatus,
			[gameId]: {
				...state.gameChecklistStatus[gameId],
				[level]: [
					...state.gameChecklistStatus[gameId]?.[level] || [],
				],
			},
		};
		newGameCheclistStatus[gameId][level][step - 1] = { status };

		return {
			...state,
			gameChecklistStatus: {
				...newGameCheclistStatus,
			},
		};
	}

	if (action.type === setHasPlaytestNotifications.type) {
		return {
			...state,
			hasPlaytestNotifications: action.isEnabled,
		};
	}

	return state;
}
