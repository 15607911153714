{
    "engine": "Unity",
    "sub-title": "C++ Engine and Editor",
    "official-partner": false,
    "website": "https://unity.com/",
    "documentation": "https://docs.unity3d.com/Manual/index.html",
    "community-links": [
		{
      		"label": "Unity Forum",
			"url":"https://forum.unity.com/"
    	}
	],
    "description": "Unity is a 2D and 3D capable engine that can export to almost any platform and is of the most-used engines for game development. Its large community and Asset Library make this engine easy to learn and useful for any size project. Due to its large initial file size, Unity games are overall less well-suited for the web.",
    "games": [
        {
            "title": "Hills Of Steel",
            "url": "https://poki.com/en/g/hills-of-steel",
            "developer": "Superplus Games"
        },
        {
            "title": "Apple Knight",
            "url": "https://poki.com/en/g/apple-knight",
            "developer": "Limitless"
        },
        {
            "title": "Repuls.io",
            "url": "https://poki.com/en/g/repuls-io",
            "developer": "Docksi"
        }
    ],
    "sections": {
       "tech-fit": {
         "file-size-of-empty-project": {
           "stars": 1,
           "description": "The file size is 15MB when built with Unity."
         },
         "support-for-mobile-web": {
           "stars": 1,
           "description": "Unity has built-in support for touch controls and layouts for Landscape and Portrait modes. These are both very helpful when making games for the mobile web. A downside for mobile web is the large file size of Unity projects. On lower-end devices or devices with a slower internet connections, these games can take quite a while to load. Optimising your loading structures and compressing down all assets and sound files really helps with this, but it does take extra effort."
         }
       },
       "capabilities": {
         "2D": {
           "stars": 3,
           "description": "Unity features suitable tools for 2D game development. Built into the engine is support for 2D sprites, bone animations, lights and shaders. It also has apt support for 2D physics and animations."
         },
         "3D": {
           "stars": 3,
           "description": "3D development is what Unity is more known for. Its 3D features are considered to be very good, having the capability of rendering photo-realistic scenes. A particle system, 3D animations and 3D physics also come packed with the Unity editor."
         },
         "multiplayer": {
           "stars": 2,
           "description": "Unity has a lot of multiplayer features available through their Asset Store. For almost all often-used back-end services, there are plug-ins available to make connection to your game easier."
         }
       },
       "team-fit": {
         "collaboration": {
           "stars": 3,
           "description": "The Unity editor is easily customisable or extendable to fit your project. The editor has a scene layout module, that makes it easy for the graphical part of your team to work together in the same editor. If you are new to Unity, there are a lot of tutorials and examples available online for this engine that can help you get started."
         },
         "licensing": {
           "stars": 2,
           "description": "Unity has two tiers of licenses. The first tier is free and applies for developers who earn under 100.000 euros from their products. If you earn more than that, you are in the Paid tier. The costs within the Paid can vary based on your needs and the size of your company. Check out the Unity site for more information."
         }
       }
    }
}
