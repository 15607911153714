import { createAuthorizedApiEpic, sortPageFilterQuery } from 'app/src/utils/api';
import getApiUrl from 'app/src/utils/getApiUrl';
import dataFormatter from 'app/src/utils/dataFormatter';

export const exportUpfrontPaymentsCsv = createAuthorizedApiEpic(
	'team/export_upfront_payments_csv',
	(callApi, { year }) => callApi({
		url: getApiUrl('devs', `upfront-payments/@sheet?year=${year}`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
	}),
);

export const listAllUpfrontPayments = createAuthorizedApiEpic(
	'team/list_upfront_payments',
	(callApi, { sortField, sortDirection, page = 1, perPage = 1000000000 }) => callApi({
		url: getApiUrl('devs', `upfront-payments?${sortPageFilterQuery({ sortField, sortDirection, page, perPage })}`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
	}),
);

export const listUpfrontPaymentsByTeamId = createAuthorizedApiEpic(
	'team/list_upfront_payments_by_team_id',
	(callApi, { teamId, sortField, sortDirection, page = 1, perPage = 1000000000 }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/relationships/upfront-payments?${sortPageFilterQuery({ sortField, sortDirection, page, perPage })}`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
	}),
);

export const createUpfrontPaymentForTeamId = createAuthorizedApiEpic(
	'team/create_upfront_payment',
	(callApi, { teamId, amount, startDate, internalNotes, gameIds }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/relationships/upfront-payments`),
		method: 'POST',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				type: 'upfront_payments',
				amount: amount * 100,
				start_date: startDate.getTime() / 1000,
				internal_notes: internalNotes,
				games: gameIds.map(gId => ({
					type: 'games',
					id: gId,
				})),
				relationshipNames: ['games'],
			},
		})),
	}),
);

export const listAllMinimumGuarantees = createAuthorizedApiEpic(
	'team/list_minimum_guarantees',
	(callApi, { sortField, sortDirection, page = 1, perPage = 1000000000 }) => callApi({
		url: getApiUrl('devs', `minimum-guarantees?${sortPageFilterQuery({ sortField, sortDirection, page, perPage })}`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
	}),
);

export const listMinimumGuaranteesByTeamId = createAuthorizedApiEpic(
	'team/list_minimum_guarantees_by_team_id',
	(callApi, { teamId, sortField, sortDirection, page = 1, perPage = 1000000000 }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/relationships/minimum-guarantees?${sortPageFilterQuery({ sortField, sortDirection, page, perPage })}`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
	}),
);

export const createMinimumGuaranteeForTeamId = createAuthorizedApiEpic(
	'team/create_minimum_guarantee',
	(callApi, { teamId, monthlyAmount, startDate, months, internalNotes }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/relationships/minimum-guarantees`),
		method: 'POST',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
		body: JSON.stringify(dataFormatter.serialize({
			stuff: {
				type: 'minimum_guarantees',
				monthly_amount: monthlyAmount * 100,
				start_date: startDate.getTime() / 1000,
				months,
				internal_notes: internalNotes,
			},
		})),
	}),
);

export const listAllRecurringMonthlyPayments = createAuthorizedApiEpic(
	'team/list_recurring_monthly_payments',
	(callApi, { sortField, sortDirection, page = 1, perPage = 1000000000 }) => callApi({
		url: getApiUrl('devs', `recurring-monthly-payments?${sortPageFilterQuery({ sortField, sortDirection, page, perPage })}`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
	}),
);

export const listRecurringMonthlyPaymentsByTeamId = createAuthorizedApiEpic(
	'team/list_recurring_monthly_payments_by_team_id',
	(callApi, { teamId, sortField, sortDirection, page = 1, perPage = 1000000000 }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/relationships/recurring-monthly-payments?${sortPageFilterQuery({ sortField, sortDirection, page, perPage })}`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
	}),
);

export const createRecurringMonthlyPaymentForTeamId = createAuthorizedApiEpic(
	'team/create_recurring_monthly_payment',
	(callApi, { teamId, type, monthlyAmount, startDate, months, internalNotes }) => {
		const body = dataFormatter.serialize({
			stuff: {
				type: 'recurring_monthly_payments',
				monthly_amount: monthlyAmount * 100,
				start_date: startDate.getTime() / 1000,
				months,
				internal_notes: internalNotes,
			},
		});

		// FML.
		body.data.attributes.type = type;

		return callApi({
			url: getApiUrl('devs', `teams/${teamId}/relationships/recurring-monthly-payments`),
			method: 'POST',
			headers: {
				Accept: 'application/vnd.api+json',
				'Content-Type': 'application/vnd.api+json',
			},
			body: JSON.stringify(body),
		});
	},
);

export const listOneOffPaymentsByTeamId = createAuthorizedApiEpic(
	'team/list_one_off_payments_by_team_id',
	(callApi, { teamId, sortField, sortDirection, page = 1, perPage = 1000000000 }) => callApi({
		url: getApiUrl('devs', `teams/${teamId}/relationships/one-off-payments?${sortPageFilterQuery({ sortField, sortDirection, page, perPage })}`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
	}),
);

export const listAllOneOffPayments = createAuthorizedApiEpic(
	'team/list_all_one_off_payments',
	(callApi, { sortField, sortDirection, page = 1, perPage = 1000000000 }) => callApi({
		url: getApiUrl('devs', `one-off-payments?${sortPageFilterQuery({ sortField, sortDirection, page, perPage })}`),
		method: 'GET',
		headers: {
			Accept: 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json',
		},
	}),
);

export const createOneOffPaymentForTeamId = createAuthorizedApiEpic(
	'team/create_one_off_payment',
	(callApi, { teamId, amount, type, sendDate, internalNotes, targetTeamId, gameIds, separateInvoice }) => {
		const body = dataFormatter.serialize({
			stuff: {
				type: 'one_off_payments',
				amount: amount * 100,
				send_date: sendDate.getTime() / 1000,
				internal_notes: internalNotes,
				separate_invoice: !!separateInvoice,
				games: gameIds.map(gId => ({
					type: 'games',
					id: gId,
				})),
				target_team: targetTeamId ? {
					type: 'teams',
					id: targetTeamId,
				} : undefined,
				relationshipNames: ['games', 'target_team'],
			},
		});

		// FML.
		body.data.attributes.type = type;

		return callApi({
			url: getApiUrl('devs', `teams/${teamId}/relationships/one-off-payments`),
			method: 'POST',
			headers: {
				Accept: 'application/vnd.api+json',
				'Content-Type': 'application/vnd.api+json',
			},
			body: JSON.stringify(body),
		});
	},
);
