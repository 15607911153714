{
	"engine": "Stencyl",
	"sub-title": "Haxe visual editor and engine",
	"official-partner": false,
	"website": "https://www.stencyl.com/",
	"documentation": "https://www.stencyl.com/help/",
	"community-links": [
		{
			"label": "Forum",
			"url": "https://community.stencyl.com/"
		}
	],
	"description": "Based on the Haxe scripting language, Stencyl is a editor and engine supporting mostly 2D games. Its visual coding setup and graphical Scene Designer makes working with Stencyl more a process of clicking your game together, rather than typing scripts.",
	"games": [
		{
			"title": "Level Devil",
			"url": "https://poki.com/en/g/level-devil",
			"developer": "Unept"
		},
		{
			"title": "Jumping Shell",
			"url": "https://poki.com/en/g/jumping-shell",
			"developer": "Robert Alvarez"
		},
		{
			"title": "Poor Bunny",
			"url": "https://poki.com/en/g/poor-bunny",
			"developer": "Adventure Islands"
		}
	],
	"sections": {
		"tech-fit": {
			"file-size-of-empty-project": {
				"stars": 3,
				"description": "An empty, compressed Stencyl project is 500KB in size."
			},
			"support-for-mobile-web": {
				"stars": 3,
				"description": "A small initial file size combined with touch control support and the built-in option to scale your game to landscape makes Stencyl a good choice for mobile (web) games. Currently limited functionality for portrait orientation but being improved on!"
			}
		},
		"capabilities": {
			"2D": {
				"stars": 3,
				"description": "Stencyl supports 2D games in sidescrolling and isometric layouts. Natively, Stencyl supports Box2D for physics, but if needed this system can be swapped out for a more simple system. It also supports tweens and classic frame-based animations."
			},
			"3D": {
				"stars": 0,
				"description": "Stencyl has no 3D capabilities"
			},
			"multiplayer": {
				"stars": 0,
				"description": "Stencyl does not support online multiplayer"
			}
		},
		"team-fit": {
			"collaboration": {
				"stars": 3,
				"description": "Stencyl games can be made entirely without code in the Stencyl editor. Instead, a graphical editor is used to click different types of behavior together. This more visual approach to programming can make it easier for team members to get into the development. The built-in Scene Designer is also quite close to Photoshop, sharing a similar layout and selection of tools. Team members that are already familiar with visual editing software should be able to find their way in the Stencyl editor quite easily. Integration with a versioning control system is possible, but not natively supported in Stencyl."
			},
			"licensing": {
				"stars": 3,
				"description": "Publishing games to the web is free with Stencyl. If you want to publish to other platforms as well, you can purchase a yearly subscription, for which there are two tiers."
			}
		}
	}
}