import { useSelector } from 'react-redux';

import getParameterByName from 'app/src/utils/getParameterByName';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export const getIsAuthenticating = () => !!getParameterByName('exchange_token');

export const selectAccessToken = state => state.persistedSession.accessToken;
export const selectRefreshToken = state => state.persistedSession.refreshToken;
export const selectPreviousRefreshToken = state => state.persistedSession.previousRefreshToken;
export const selectTokenExpires = state => state.persistedSession.expires;
export const selectIsDarkMode = state => state.persistedSession.colorScheme === 'dark';
export const selectIsPersistedHiddenMessageBox = (id, state) => state.persistedSession.persistedHiddenMessageboxes?.[id];
export const selectIsLoggingOutWithNewEmail = state => state.session.isLoggingOutWithNewEmail;
export const selectCachedURLTeamCode = state => state.persistedSession.cachedURLTeamCode;
export const selectIsRefreshing = state => state.session.isRefreshing;
export const selectVisitedTeams = state => state.persistedSession.visitedTeams;
export const selectIsInvoicePrepared = (id, state) => state.persistedSession.preparedInvoices && state.persistedSession.preparedInvoices.includes(id);
export const selectErrorDetailsPagination = (id, state) => state.persistedSession.errorDetailsPagination?.[id];
export const selectRedirectPathname = state => state.persistedSession.redirectPathname;
export const selectGameChecklistStatus = (gameId, state) => state.persistedSession.gameChecklistStatus?.[gameId];
export const selectGameChecklistLevelStatus = (gameId, level, state) => state.persistedSession.gameChecklistStatus?.[gameId]?.[level];
export const selectHasPlaytestNotifications = state => state.persistedSession.hasPlaytestNotifications;

export const useSelectIsAuthenticated = () => useSelector(state => !!selectAccessToken(state) && !!selectRefreshToken(state) && !!selectTokenExpires(state));
export const useSelectRefreshToken = () => useSelector(state => selectRefreshToken(state));
export const useSelectPreviousRefreshToken = () => useSelector(state => selectPreviousRefreshToken(state));
export const useSelectTheme = () => useContext(ThemeContext);
export const useSelectIsDarkMode = () => useSelector(state => selectIsDarkMode(state));
export const useSelectIsPersistedHiddenMessageBox = id => useSelector(state => selectIsPersistedHiddenMessageBox(id, state));
export const useSelectIsLoggingOutWithNewEmail = () => useSelector(state => selectIsLoggingOutWithNewEmail(state));
export const useSelectCachedURLTeamCode = () => useSelector(state => selectCachedURLTeamCode(state));
export const useSelectVisitedTeams = () => useSelector(state => selectVisitedTeams(state));
export const useSelectIsInvoicePrepared = id => useSelector(state => selectIsInvoicePrepared(id, state));
export const useSelectErrorDetailsPagination = id => useSelector(state => selectErrorDetailsPagination(id, state));
export const useSelectRedirectPathname = () => useSelector(state => selectRedirectPathname(state));
export const useSelectGameChecklistStatus = gameId => useSelector(state => selectGameChecklistStatus(gameId, state));
export const useSelectGameChecklistLevelStatus = (gameId, level) => useSelector(state => selectGameChecklistLevelStatus(gameId, level, state));
export const useSelectHasPlaytestNotifications = () => useSelector(state => selectHasPlaytestNotifications(state));
