{
	"engine": "Defold",
	"sub-title": "C++ Engine and Editor",
	"official-partner": true,
	"website": "https://defold.com/",
	"documentation": "https://defold.com/learn/",
	"community-links": [
		{
			"label": "Forum",
			"url": "https://forum.defold.com/"
		}
	],
	"description": "Defold is a web focused game engine with 2D and 3D capabilities. It features an extensive editor, complete with a Lua code editor, GUI editor and animation tools. Defold’s documentation is very detailed and contains tons of useful example projects and tutorials. Mixed with the passionate community, this is a great choice for any dev!",
	"games": [
		{
			"title": "Car Parking Jam",
			"url": "https://poki.com/en/g/car-parking-jam",
			"developer": "Refold"
		},
		{
			"title": "Monkey Mart",
			"url": "https://poki.com/en/g/monkey-mart",
			"developer": "TinyDobbins"
		},
		{
			"title": "Pixel Realms",
			"url": "https://poki.com/en/g/pixel-realms",
			"developer": "Yury Grigoryev"
		}
	],
	"sections": {
		"tech-fit": {
			"file-size-of-empty-project": {
				"stars": 3,
				"description": "An empty Defold project is 1.03MB in size. With further optimization, this could be made even smaller."
			},
			"support-for-mobile-web": {
				"stars": 2,
				"description": "Games made with Defold are very well suited to run on mobile devices. It has built-in options for touch controls, responsive scaling for portrait and landscape orientations and a small initial file size. This will ensure your game can run well on older devices and in any layout."
			}
		},
		"capabilities": {
			"2D": {
				"stars": 3,
				"description": "Defold is 2D focused with tilemaps, sprites, text options, particle effects, GUI scenes and even Spine and Rive 2D skeleton animations. It uses an orthographic camera to render scenes and uses Box2D for physics."
			},
			"3D": {
				"stars": 2,
				"description": "Defold can accept models in .gLTF format, including animations. Meshes are created during runtime, further ensuring a low initial file size. It also features a 3D particle system, options to create your own custom materials and shaders (written in GLSL) and Bullet Physics."
			},
			"multiplayer": {
				"stars": 3,
				"description": "With built-in integrations for popular back-end services like Nakama, PlayFab, Colyseus and Web Sockets, Defold is well-equipped for multiplayer games. Extensions for even more services are available through the Defold Asset Portal."
			}
		},
		"team-fit": {
			"collaboration": {
				"stars": 3,
				"description": "Defold comes out of the box with a Git version control system, making it easy to integrate your GitHub repositories and work together. All project files also use a merge-friendly text format. Defold has integrations for IDE’s such as Atom, VS Code, Spine and Tiled as well. On top of this, Defold is also artist friendly, allowing designers to bring their ideas for characters, screens and user interfaces to life in-editor."
			},
			"licensing": {
				"stars": 3,
				"description": "Defold is completely open source. Licenses are given out for free and allow you to fully commercialise your games without having to worry about licensing fees or royalties."
			}
		}
	}
}