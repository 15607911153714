import { ofType } from 'redux-observable';
import { tap, ignoreElements } from 'rxjs';

import { setGameChecklistStatus } from 'app/src/actions/session';
import { pushEvent } from 'app/src/utils/tracking';

export function trackSetGameChecklistStatusEpic(action$) {
	return action$.pipe(
		ofType(setGameChecklistStatus.type),
		tap(({ gameId, level, step, status }) => {
			pushEvent('gameCreateFlow', 'setGameChecklistStatus', { gameId, level, step, status });
		}),
		ignoreElements(),
	);
}
