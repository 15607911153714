import { search } from 'app/src/epics/search';
import dataFormatter from 'app/src/utils/dataFormatter';
import { clearSearchResults } from 'app/src/actions/search';

const initialState = {
	searchResults: [],
};

export default function searchReducer(state = initialState, action) {
	if (action.type === search.success.type) {
		const { response } = action.payload.result;
		const { links, meta } = response;

		return {
			...state,
			searchResults: {
				data: dataFormatter.deserialize(response),
				links,
				meta,
			},
		};
	}

	if (action.type === search.error.type) {
		return {
			...state,
			searchResults: [],
		};
	}

	if (action.type === clearSearchResults.type) {
		return {
			...state,
			searchResults: [],
		};
	}

	return state;
}
