import { SPECIAL_OPERATORS } from 'shared/vars';

const specialFilterExpressions = [
	{
		id: 'less',
		operator: '<',
	},
	{
		id: 'lessOrEqual',
		operator: '<=',
	},
	{
		id: 'greater',
		operator: '>',
	},
	{
		id: 'greaterOrEqual',
		operator: '>=',
	},
];

const filtersToExpressions = (filters = {}) => (
	Object.keys(filters).map(field => {
		const values = filters[field];
		const hasSpecialOperator = Object.keys(SPECIAL_OPERATORS).some(operator => field.startsWith(`${operator}:`));

		if (Array.isArray(values)) {
			const hasNonStandardParts = values.some(value => (
				specialFilterExpressions.some(({ id }) => typeof value === 'string' && value.startsWith(`${id}:`))
			));

			if (hasNonStandardParts) {
				// We have some non-standard parts, we can't get away with an IN
				return {
					operator: 'or',
					expressions: values.map(value => {
						const [filterExpressionId, realValue] = value.split(':');
						const filterExpression = specialFilterExpressions.find(f => f.id === filterExpressionId);

						return [field, filterExpression?.operator || '==', realValue];
					}),
				};
			}

			if (hasSpecialOperator) {
				const [operator, realField] = field.split(':');
				return SPECIAL_OPERATORS[operator](realField, filters[field]);
			}

			// Everything is standard, just do an IN
			return [field, 'in', filters[field]];
		} else {
			if (!filters[field]) {
				return undefined;
			}

			if (hasSpecialOperator) {
				const [operator, realField] = field.split(':');

				return SPECIAL_OPERATORS[operator](realField, filters[field]);
			}

			// Single value, just do a standard ==
			return [field, '==', filters[field]];
		}
	})
);

export default filtersToExpressions;
