{
	"engine": "Cocos Creator",
	"sub-title": "JavaScript/Typescript Editor and Engine",
	"official-partner": false,
	"website": "https://www.cocos.com/en/creator",
	"documentation": "https://docs.cocos.com/creator/manual/en/",
	"community-links": [
		{
			"label": "Forum",
			"url": "https://discuss.cocos2d-x.org/"
		}
	],
	"description": "Cocos Creator enables the creation of cross-platform 2D and 3D games. The engine provides a visual editor for designing game scenes, a powerful scripting language (JavaScript or TypeScript), and a comprehensive set of tools for animation, physics, and UI development. Cocos Creator is known for its ease of use and efficiency in developing both casual and more complex games.",
	"games": [
		{
			"title": "Merge Arena",
			"url": "https://poki.com/en/g/merge-arena",
			"developer": "Easycats"
		},
		{
			"title": "Kawaii Fruits 3D",
			"url": "https://poki.com/en/g/kawaii-fruits-3d",
			"developer": "Okashi Games"
		},
		{
			"title": "TinyTownRacing",
			"url": "https://poki.com/en/g/tinytownracing",
			"developer": "Vkrishna"
		}
	],
	"sections": {
		"tech-fit": {
			"file-size-of-empty-project": {
				"stars": 3,
				"description": "The file size of an empty Cocos project is 709kb."
			},
			"support-for-mobile-web": {
				"stars": 3,
				"description": "With small export file sizes, support for touch controls and options to responsively scale your games, Cocos Creator is a good fit for games on the (mobile) web."
			}
		},
		"capabilities": {
			"2D": {
				"stars": 3,
				"description": "Cocos Creator supports both 2D and 3D games. For 2D games, it features built-in components for UI, animations, tilemaps, particle system and more. Box2D is used for 2D physics."
			},
			"3D": {
				"stars": 3,
				"description": "For 3D games, Cocos Creator has options for glTF 2.0 and FBX support, material system, PBR rendering, image based lighting, light maps, different render pipelines (Forward, Deferred, Custom), post processing, Animation Graph, particle effects, terrain, localization and more. Cocos can use Bullet Physics and PhysX physics."
			},
			"multiplayer": {
				"stars": 2,
				"description": "Third party options are available to integrate multiplayer back-end services like Colyseus, Websockets, Nakama or PlayFab into your game."
			}
		},
		"team-fit": {
			"collaboration": {
				"stars": 3,
				"description": "Cocos Creator has an extendable editor with in-engine animation, terrain and particle editors. The Cocos store has a lot of tutorials, extensions and other helpful options available to help you on your way with the engine. On top of this, its possible to integrate Git or other version tracking programs into your Cocos project to work together with your team."
			},
			"licensing": {
				"stars": 3,
				"description": "Cocos Creator is free to use. No license fee or royalty payments are required to release games made with this engine."
			}
		}
	}
}