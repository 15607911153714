{
	"engine": "PlayCanvas",
	"sub-title": "JavaScript and Typescript Editor and Engine",
	"official-partner": false,
	"website": "https://playcanvas.com/",
	"documentation": "https://developer.playcanvas.com/user-manual/",
	"community-links": [
		{
			"label": "Forum",
			"url": "https://forum.playcanvas.com/"
		}
	],
	"description": "PlayCanvas is a dynamic game engine designed for the web, capable of rendering 2D and 3D projects alike. With a robust editor, extensive documentation and collaborative features, PlayCanvas provides a comprehensive solution for game development on the web.",
	"games": [
		{
			"title": "Venge.io",
			"url": "https://poki.com/en/g/venge-io",
			"developer": "OnRush Studios"
		},
		{
			"title": "Crazy Cars",
			"url": "https://poki.com/en/g/crazy-cars",
			"developer": "No Pressure Studios"
		},
		{
			"title": "Sword Masters",
			"url": "https://poki.com/en/g/sword-masters",
			"developer": "emolingo games"
		}
	],
	"sections": {
		"tech-fit": {
			"file-size-of-empty-project": {
				"stars": 3,
				"description": "An empty PlayCanvas project is 300kb in size."
			},
			"support-for-mobile-web": {
				"stars": 3,
				"description": "Games made with PlayCanvas are very well suited to run on mobile devices. It has built-in options responsive scaling for Portrait and Landscape orientations and a small initial file size."
			}
		},
		"capabilities": {
			"2D": {
				"stars": 3,
				"description": "PlayCanvas has a set of 2D development natively available. Tilemaps, sprites, text options, particle effects and GUI scenes are provided out of the box."
			},
			"3D": {
				"stars": 3,
				"description": "Supporting models in .gLTF format with animations, the engine dynamically creates meshes during runtime, ensuring a compact initial file size. PlayCanvas also has a 3D particle system, customizable materials, and GLSL shaders available for use."
			},
			"multiplayer": {
				"stars": 3,
				"description": "Playcanvas supports integration with multiple different back-end systems. Tutorials and examples for making connections are further detailed in the documentation."
			}
		},
		"team-fit": {
			"collaboration": {
				"stars": 3,
				"description": "PlayCanvas has a collaborative development with Git version control integration. This simplifies the GitHub repository management. Project files use a merge-friendly text format, which makes collaboration easier."
			},
			"licensing": {
				"stars": 2,
				"description": "While the engine itself is open source, users can access additional features and services with subscription-based plans. Free licenses are available, allowing developers to commercialise their games without worrying about licensing fees or royalties."
			}
		}
	}
}