import { listUpfrontPaymentsByTeamId, listAllUpfrontPayments, listAllMinimumGuarantees, listMinimumGuaranteesByTeamId, listAllRecurringMonthlyPayments, listRecurringMonthlyPaymentsByTeamId, listOneOffPaymentsByTeamId, listAllOneOffPayments } from 'app/src/epics/additionalPayments';
import dataFormatter from 'app/src/utils/dataFormatter';

const initialState = {
	upfrontPaymentsByTeamId: {},
	allUpfrontPayments: {},
	minimumGuaranteesByTeamId: {},
	allMinimumGuarantees: {},
	recurringMonthlyPaymentsByTeamId: {},
	allRecurringMonthlyPayments: {},
	oneOffPaymentsByTeamId: {},
	allOneOffPayments: {},
};

export default function additionalPaymentsReducer(state = initialState, action) {
	if (action.type === listUpfrontPaymentsByTeamId.success.type) {
		const { result } = action.payload;
		const { response } = result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			upfrontPaymentsByTeamId: {
				...state.upfrontPaymentsByTeamId,

				[action.payload.options.teamId]: {
					data,
					links,
					meta,
				},
			},
		};
	}

	if (action.type === listAllUpfrontPayments.success.type) {
		const { result } = action.payload;
		const { response } = result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			allUpfrontPayments: {
				data,
				links,
				meta,
			},
		};
	}

	if (action.type === listMinimumGuaranteesByTeamId.success.type) {
		const { result } = action.payload;
		const { response } = result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			minimumGuaranteesByTeamId: {
				...state.minimumGuaranteesByTeamId,

				[action.payload.options.teamId]: {
					data,
					links,
					meta,
				},
			},
		};
	}

	if (action.type === listAllMinimumGuarantees.success.type) {
		const { result } = action.payload;
		const { response } = result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			allMinimumGuarantees: {
				data,
				links,
				meta,
			},
		};
	}

	if (action.type === listRecurringMonthlyPaymentsByTeamId.success.type) {
		const { result } = action.payload;
		const { response } = result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			recurringMonthlyPaymentsByTeamId: {
				...state.recurringMonthlyPaymentsByTeamId,

				[action.payload.options.teamId]: {
					data,
					links,
					meta,
				},
			},
		};
	}

	if (action.type === listAllRecurringMonthlyPayments.success.type) {
		const { result } = action.payload;
		const { response } = result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			allRecurringMonthlyPayments: {
				data,
				links,
				meta,
			},
		};
	}

	if (action.type === listOneOffPaymentsByTeamId.success.type) {
		const { result } = action.payload;
		const { response } = result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			oneOffPaymentsByTeamId: {
				...state.oneOffPaymentsByTeamId,

				[action.payload.options.teamId]: {
					data,
					links,
					meta,
				},
			},
		};
	}

	if (action.type === listAllOneOffPayments.success.type) {
		const { result } = action.payload;
		const { response } = result;
		const { links, meta } = response;
		const data = dataFormatter.deserialize(response);

		return {
			...state,
			allOneOffPayments: {
				data,
				links,
				meta,
			},
		};
	}

	return state;
}
