import { SERVICE_ENV } from 'app/vars';

const config = {
	page: {
		view: () => ({
			mxUserEvent: true,
			mxTeamEvent: true,
			incrementSessionDepth: true,
		}),
	},

	login: {
		successfulLogin: data => ({
			mxUserEvent: true,
			mxData: {
				provider: data.provider,
			},
		}),
	},

	gameSettings: {
		changeThumbnail: () => ({
			mxUserEvent: true,
			mxTeamEvent: true,
		}),
	},

	permalink: {
		copy: () => ({
			mxUserEvent: true,
			mxTeamEvent: true,
		}),
	},

	gameVersions: {
		addZipFile: data => ({
			label: data.gameId,
			mxUserEvent: true,
			mxTeamEvent: true,
			mxData: {
				gameId: data.gameId,
			},
		}),
		previewVersion: data => ({
			mxUserEvent: true,
			mxTeamEvent: true,
			mxData: {
				versionId: data.versionId,
			},
		}),
		qaVersion: data => ({
			mxUserEvent: true,
			mxTeamEvent: true,
			mxData: {
				versionId: data.versionId,
			},
		}),
	},

	invoice: {
		download: () => ({
			mxUserEvent: true,
			mxTeamEvent: true,
		}),
	},

	dashboard: {
		changeDateRange: data => ({
			mxUserEvent: true,
			mxData: {
				days: data.days,
			},
		}),
	},

	gameOverview: {
		changeDateRange: data => ({
			mxUserEvent: true,
			mxData: {
				days: data.days,
			},
		}),
	},

	playerFeedback: {
		expand: data => ({
			mxUserEvent: true,
			mxTeamEvent: true,
			mxData: {
				rating: data.rating,
				countryCode: data.countryCode,
			},
		}),
	},

	navigation: {
		clickDiscordServer: () => ({
			mxUserEvent: true,
			mxTeamEvent: true,
		}),
	},

	user: {
		viewAs: data => ({
			mxUserEvent: true,
			mxTeamEvent: true,
			mxData: {
				teamId: data.teamId,
				userRole: data.userRole,
			},
		}),
	},

	playtest: {
		searching: () => ({
			mxUserEvent: true,
			mxTeamEvent: true,
		}),
		connected: data => ({
			mxUserEvent: true,
			mxTeamEvent: true,
			mxData: {
				duration: data.duration, // How long the developer had to wait (in ms).
				sharingURL: data.sharingURL,
				playSound: data.playSound,
			},
		}),
		disconnected: data => ({
			mxUserEvent: true,
			mxTeamEvent: true,
			mxData: {
				duration: data.duration, // How long the playtest was (in ms).
			},
		}),
	},
	gameCreateFlow: {
		termsAndConditions: data => ({
			mxUserEvent: true,
			mxTeamEvent: true,
			mxData: {
				gameId: data.gameId,
			},
		}),
		setGameChecklistStatus: data => ({
			mxUserEvent: true,
			mxTeamEvent: true,
			mxData: {
				gameId: data.gameId,
				level: data.level,
				step: data.step,
				status: data.status,
			},
		}),
		requestWebFitTestIncomplete: data => ({
			mxUserEvent: true,
			mxTeamEvent: true,
			mxData: {
				gameId: data.gameId,
				checklist: data.checklist,
			},
		}),
		requestWebFitTest: data => ({
			mxUserEvent: true,
			mxTeamEvent: true,
			mxData: {
				gameId: data.gameId,
			},
		}),
	},
};

// Shared event data between events
const eventContext = {};

export const setEventContext = (key, value) => {
	eventContext[key] = value;
};

let mixpanelInitialized = false;

// Event queue responsible for keeping events that were fired
// before tracking is initialized, so we can send them when tracking is initialized
const eventQueue = [];

const queueEvent = (eventNoun, eventVerb, eventData = {}) => {
	eventQueue.push({ eventNoun, eventVerb, eventData });
};

const queueCatchup = () => {
	// For now this method is dumb, and we only catch up when all trackers
	// are initialized. This could be split up, but will make everything more complicated
	// and it's unnecessary at the moment
	if (!mixpanelInitialized) return;

	eventQueue.forEach(({ eventNoun, eventVerb, eventData }) => {
		pushEvent(eventNoun, eventVerb, eventData); // eslint-disable-line no-use-before-define
	});
};

const initMixpanel = () => {
	if (mixpanelInitialized) return;

	/* eslint-disable */
	(function(c,a){if(!a.__SV){var b=window;try{var d,m,j,k=b.location,f=k.hash;d=function(a,b){return(m=a.match(RegExp(b+"=([^&]*)")))?m[1]:null};f&&d(f,"state")&&(j=JSON.parse(decodeURIComponent(d(f,"state"))),"mpeditor"===j.action&&(b.sessionStorage.setItem("_mpcehash",f),history.replaceState(j.desiredHash||"",c.title,k.pathname+k.search)))}catch(n){}var l,h;window.mixpanel=a;a._i=[];a.init=function(b,d,g){function c(b,i){var a=i.split(".");2==a.length&&(b=b[a[0]],i=a[1]);b[i]=function(){b.push([i].concat(Array.prototype.slice.call(arguments,
	0)))}}var e=a;"undefined"!==typeof g?e=a[g]=[]:g="mixpanel";e.people=e.people||[];e.toString=function(b){var a="mixpanel";"mixpanel"!==g&&(a+="."+g);b||(a+=" (stub)");return a};e.people.toString=function(){return e.toString(1)+".people (stub)"};l="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
	for(h=0;h<l.length;h++)c(e,l[h]);var f="set set_once union unset remove delete".split(" ");e.get_group=function(){function a(c){b[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));e.push([d,call2])}}for(var b={},d=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<f.length;c++)a(f[c]);return b};a._i.push([b,d,g])};a.__SV=1.2;b=c.createElement("script");b.type="text/javascript";b.async=!0;b.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
	MIXPANEL_CUSTOM_LIB_URL:"file:"===c.location.protocol&&"//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//)?"https://cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js";d=c.getElementsByTagName("script")[0];d.parentNode.insertBefore(b,d)}})(document,window.mixpanel||[]);
	/* eslint-enable */

	const pokiMixpanelToken = SERVICE_ENV === 'production' ? '580e7ce4e73220da99381338259a9a0f' : 'e1f722ef984ee84fbccc1d54432daa0d';
	const inspectorMixpanelToken = '375313efebc23edf9976bada3cf93775';

	// Initialize the inspector tracker
	window.mixpanel.init(inspectorMixpanelToken, {}, 'inspector');
	window.mixpanel.init(pokiMixpanelToken, {}, 'user');
	window.mixpanel.init(pokiMixpanelToken, {}, 'team');
	window.mixpanel.user.register({ eventType: 'user' });
	window.mixpanel.team.register({ eventType: 'team' });

	mixpanelInitialized = true;

	queueCatchup();
};

const trackInspectorEvent = (eventNoun, eventVerb, eventData = {}) => {
	const { mixpanel } = window;

	if (mixpanel) {
		if (!mixpanelInitialized || process.env.NODE_ENV === 'development') {
			console.error('Attempted Mixpanel inspector tracking but not initialized');
		} else if (eventNoun && eventVerb) {
			mixpanel.inspector.track(`${eventNoun}-${eventVerb}`, {
				...eventContext,
				...eventData,
			});
		}
	}
};

const trackUserAndTeam = (user, activeTeam) => {
	const { mixpanel } = window;

	if (mixpanel) {
		if (!mixpanelInitialized) {
			if (process.env.NODE_ENV === 'development') {
				console.error('Attempted Mixpanel user and team tracking but not initialized');
			}
		} else {
			if (user && user.id && user.team && user.team.id) {
				// Calling mixpanel.user.alias here and below completely messes
				// up the user tracking in Mixpanel. Mixpanel doesn't seem to support
				// calling alias with multiple trackers on the same page :(
				mixpanel.user.identify(user.id);

				mixpanel.user.register({
					distinct_id: user.id,
					userId: user.id,
					userRole: user.role,
					userName: user.name,
					userTeamId: user.team.id, // Team you are.
					userTeamName: user.team.name,
					userTeamVerified: user.team.verified,
					activeTeamId: activeTeam && activeTeam.id, // Team you are looking at.
					activeTeamName: activeTeam && activeTeam.name,
					activeTeamVerified: activeTeam && activeTeam.verified,
				});
			}

			if (user && user.team && user.team.id) {
				mixpanel.team.identify(user.team.id);  // Team you are.
				mixpanel.team.register({
					distinct_id: user.team.id,  // Team you are.
					activeTeamId: activeTeam && activeTeam.id, // Team you are looking at.
					activeTeamName: activeTeam && activeTeam.name,
					activeTeamVerified: activeTeam && activeTeam.verified,
				});
			}
		}
	}
};

const pushEvent = (eventNoun, eventVerb, eventData = {}) => {
	const { mixpanel } = window;

	const eventConfigGetter = config?.[eventNoun]?.[eventVerb];
	if (!eventConfigGetter) {
		if (process.env.NODE_ENV === 'development') {
			console.error(`Event ${eventNoun}-${eventVerb} not found in config`);
		}
		return;
	}

	const eventConfig = eventConfigGetter(eventData);
	if (eventConfig === false) return;

	if (((eventConfig.mxUserEvent || eventConfig.mxTeamEvent) && !mixpanelInitialized)) {
		queueEvent(eventNoun, eventVerb, eventData);
		return;
	}

	if (process.env.NODE_ENV === 'development' || SERVICE_ENV === 'acceptance') {
		console.info('📅', { eventNoun, eventVerb, eventData, eventContext: { ...eventContext }, eventConfig });
	}

	if (eventConfig.incrementSessionDepth) {
		let sessionDepth = 0;
		try {
			const d = Number(localStorage.getItem('mp_session_depth'));
			if (d > sessionDepth) {
				sessionDepth = d;
			}
		} catch (e) { /* eslint-disable no-empty */ }

		sessionDepth++;

		try {
			localStorage.setItem('mp_session_depth', sessionDepth);
		} catch (e) { /* eslint-disable no-empty */ }

		mixpanel.user.register({ sessionDepth });
		mixpanel.team.register({ sessionDepth });
	}

	const eventName = `${eventNoun}-${eventVerb}`;

	if (eventConfig.mxUserEvent) {
		mixpanel.user.track(`u_${eventName}`, {
			...eventContext,
			...(eventConfig.mxData || {}),
		});
	}

	if (eventConfig.mxTeamEvent) {
		mixpanel.team.track(`t_${eventName}`, {
			...eventContext,
			...(eventConfig.mxData || {}),
		});
	}
};

export { trackUserAndTeam, trackInspectorEvent, pushEvent, initMixpanel };
